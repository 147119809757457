import { getLetterFromIndex, getLetterIndex, merge } from 'editor/core/highcharts-editor';
import templates from 'editor/core/highed.template.plugins';
import { snakeCase, cloneDeep, isArray, isNil, isObject, unzip, zip, isString } from 'lodash';
import { getMapCodeValueIndex, toCSV, toMapCSV } from 'pages/ChartEditorPage/utils/chartEditorDataHelper';

const reservedNames = { series: 1, low: 2 };

const removeEmptyColumnsFromMatrix = (state) => {
  const { dataOptions } = state;
  const newDataGridColumns = [];
  const newMatrix = zip(...dataOptions);
  const filteredMatrix = unzip(
    newMatrix.filter((column, id) => {
      const nonEmptyColumn = !column.every((el) => isNil(el) || el === 'null');
      if (nonEmptyColumn) newDataGridColumns.push(id);
      return nonEmptyColumn;
    })
  );
  state.dataGridColumnsUsed = newDataGridColumns;
  return filteredMatrix;
};

export const saveDataAndConfigAsCSV = (state, isMap, delimiter = null) => {
  const { dataOptions, templateMeta, customizedOptions, seriesAssigns } = state;
  let newArray = [];
  if (templateMeta?.[0]?.templateParseData && templates[templateMeta[0].templateParseData].parseData) {
    const parseData = templateMeta?.[0]?.templateParseData;
    newArray = templates[parseData].parseData(cloneDeep(dataOptions), null, customizedOptions);
    // eslint-disable-next-line no-use-before-define
    setSeriesMapping(state, newArray);
  } else newArray = removeEmptyColumnsFromMatrix(state);

  const { mapValueIndex } = getMapCodeValueIndex(seriesAssigns);
  const csv = isMap
    ? toMapCSV(newArray, mapValueIndex, delimiter ?? state.customizedOptions?.data?.itemDelimiter)
    : toCSV(newArray, delimiter ?? state.customizedOptions?.data?.itemDelimiter);

  state.customizedOptions = { ...state.customizedOptions, data: { ...state.customizedOptions.data, csv } };
  state.aggregatedOptions = { ...state.aggregatedOptions, data: { ...state.aggregatedOptions.data, csv } };
};

export const clearSeriesMapping = (state) => {
  if (state.customizedOptions.data?.seriesMapping) {
    delete state.customizedOptions.data.seriesMapping;
  }
  if (state.aggregatedOptions.data?.seriesMapping) {
    delete state.aggregatedOptions.data.seriesMapping;
  }
};

export const removeRowOrColumn = (source, dataOptions, index, amount) => {
  switch (source) {
    case 'ContextMenu.removeRow':
      dataOptions.splice(index, amount);
      break;
    case 'ContextMenu.removeColumn':
      dataOptions.map((row) => row.splice(index, amount));
      break;
    default:
      break;
  }
};

export const addRowOrColumnToDataOptions = (source, dataOptions, index, amount, emptyRow) => {
  switch (source) {
    case 'ContextMenu.rowAbove':
    case 'ContextMenu.rowBelow':
      dataOptions.splice(index, amount - 1, emptyRow);
      break;
    case 'ContextMenu.columnLeft':
    case 'ContextMenu.columnRight':
      dataOptions.map((row) => row.splice(index, amount - 1, undefined));
      break;
    default:
      break;
  }
};

export const addMissingColumns = (dataOptions, index) => {
  if (dataOptions.some((dataOption) => dataOption.length < index)) {
    dataOptions.forEach((dataOption, dataOptionIndex) => {
      dataOptions[dataOptionIndex] = [
        ...dataOption,
        ...Array.from({ length: index - dataOptions[dataOptionIndex].length }, () => null)
      ];
    });
  }
};

export const addNullToClearColumnCells = (dataOptions, index, amount) => {
  dataOptions.map((row) => row.splice(index, amount, ...new Array(amount).fill(null)));
};

export const removeUnnecesaryRows = (dataOptions) => {
  let maxRowLen = 0;

  dataOptions.forEach((row, rowIndex) => {
    const rowEmpty = row.every((cell) => cell === null || cell === undefined || cell === '');
    if (!rowEmpty) maxRowLen = Math.max(rowIndex, maxRowLen);
  });

  dataOptions.splice(maxRowLen + 1, dataOptions.length - maxRowLen - 1);
};

export const parseDataNameForSeriesMapping = (name) => {
  if (isString(name) && reservedNames[(name || '').toLowerCase()]) return `_${name}`;
  return snakeCase(name);
};

export const setSeriesMapping = (state, dataOverride) => {
  let { customizedOptions, seriesAssigns, aggregatedOptions, dataOptions } = state;

  let seriesMapping = customizedOptions?.data?.seriesMapping,
    hasLabels = false,
    hasExtraColumn = [],
    extraColumnBuffer = 0;

  // Some templates need to have some custom parsing done to the seriesmapping. ex: Packed bubble
  const template = state.templateMeta;
  const templateParseData = template?.[0]?.templateParseData;
  if (templateParseData && templates[templateParseData].parseAssigns) {
    const parseAssigns = templates[template[0].templateParseData].parseAssigns;
    const seriesMapping = parseAssigns(dataOverride, seriesAssigns, null, customizedOptions);
    // Really unoptimized having to call this 'parseAssigns' twice, once we have redone the editor,
    // replace the above function to change both customized and aggregated options at once
    parseAssigns(dataOverride, seriesAssigns, null, aggregatedOptions);
    state.customizedOptions.data.seriesMapping = seriesMapping;
    state.aggregatedOptions.data.seriesMapping = seriesMapping;
    return;
  } else removeEmptyColumnsFromMatrix(state);

  seriesMapping = seriesAssigns.map((s) => {
    let serieOption = {};

    const buildSerieOption = (option) => {
      if (state.dataGridColumnsUsed.includes(option.rawValue[0])) {
        const newIndex = state.dataGridColumnsUsed.indexOf(option.rawValue[0]);
        const linkedTo = isArray(option.linkedTo) ? option.linkedTo : [option.linkedTo];
        linkedTo.forEach((linked) => {
          serieOption[linked] = newIndex;
        });
      }
    };

    Object.values(s).forEach((option) => {
      if (option.value !== '') {
        if (option.isData) buildSerieOption(option);
        else if (isObject(option)) {
          if (option.linkedTo === 'label') hasLabels = true;
          buildSerieOption(option);
        }
        if (option.addExtraColumn) {
          hasExtraColumn.push(option.linkedTo);
          extraColumnBuffer = option.addExtraColumn;
        }
      }
    });

    dataOptions[0]
      .filter((_, id) => state.dataGridColumnsUsed.includes(id))
      .forEach((cell, colIndex) => {
        if (cell) {
          serieOption = { ...serieOption, [parseDataNameForSeriesMapping(cell)]: colIndex };
        }
      });
    return serieOption;
  });

  if (seriesMapping.length > 0) {
    if (hasLabels) {
      const dataLabelOptions = {
        dataLabels: {
          enabled: true,
          format: aggregatedOptions?.plotOptions?.series?.dataLabels?.format || '{point.label}'
        }
      };

      if (customizedOptions.plotOptions) {
        const seriesPlotOptions = customizedOptions.plotOptions.series;
        const aggregatedSeriesPlotOptions = aggregatedOptions.plotOptions.series;
        merge(seriesPlotOptions, dataLabelOptions);
        merge(aggregatedSeriesPlotOptions, dataLabelOptions);
      } else {
        const option = {
          plotOptions: {
            series: dataLabelOptions
          }
        };
        merge(customizedOptions, option);
        merge(aggregatedOptions, option);
      }
    }

    if (hasExtraColumn.length) {
      seriesMapping.forEach((opt) => {
        const keys = Object.keys(opt);
        keys.forEach((key) => {
          if (hasExtraColumn.indexOf(key) === -1) {
            opt[key] += extraColumnBuffer;
          }
        });
      });
    }

    if (customizedOptions.data) {
      state.customizedOptions.data.seriesMapping = seriesMapping;
      state.aggregatedOptions.data.seriesMapping = seriesMapping;
    }
  }
};

export const updateCell = (dataOptions, row, column, newValue) => {
  const rowToUpdate = dataOptions[row];
  const emptyRow = [...Array(dataOptions[0].length)];
  const newArr = rowToUpdate || [...Array(dataOptions[0].length)];
  const dataOptionsLength = dataOptions.length;

  newArr[column] = newValue;
  dataOptions[row] = newArr;
  // this operation allows to add values in any place inside datagrid and avoid throwing HandsOnTable error

  if (row > dataOptionsLength) {
    for (let i = 0; i < dataOptions.length; i++) {
      if (!dataOptions[i]) dataOptions[i] = emptyRow;
    }
  }
};

const getAssignDataFields = (options) => {
  let all = [];

  options.forEach((option) => {
    let arr = {};
    Object.keys(option).forEach((key) => {
      if (!option[key].value) return;
      arr[key] = option[key].value;
    });
    all.push(merge({}, arr));
  });

  return all;
};

export const deleteColumnFromOptions = (customizedOptions, aggregatedOptions, key, index) => {
  customizedOptions[key].splice(index, 1);
  if (aggregatedOptions[key]) aggregatedOptions[key].splice(index, 1);
};

export const handleDeletedColumn = (state, data) => {
  let { index, amount, key: optionKey, skipRemap } = data;
  let { seriesAssigns, customizedOptions, aggregatedOptions } = state;
  for (let i = 0; i < amount; i++) {
    const letter = getLetterFromIndex(index).toUpperCase();
    getAssignDataFields(seriesAssigns).forEach((serie, seriesIndex) => {
      Object.keys(serie).forEach((key) => {
        if (serie[key] === letter) {
          if (seriesAssigns[seriesIndex] && seriesAssigns[seriesIndex][key]) {
            if (seriesAssigns[seriesIndex][key].isData) {
              // Is a data column, delete the series
              seriesAssigns.splice(seriesIndex, 1);
              deleteColumnFromOptions(customizedOptions, aggregatedOptions, optionKey, seriesIndex);
            } else if (!seriesAssigns[seriesIndex][key].isLabel) {
              // Isn't data, just remove the mapping instead
              seriesAssigns[seriesIndex][key].value = '';
              seriesAssigns[seriesIndex][key].rawValue = null;
              seriesAssigns[seriesIndex][key].previousValue = null;
            }
          }
        }
      });
    });

    if (!skipRemap) {
      getAssignDataFields(seriesAssigns).forEach((serie, seriesIndex) => {
        Object.keys(serie).forEach((key) => {
          // Go through each assigns and remove 1 from index if they are bigger than `which`
          if (getLetterIndex(serie[key]) > index) {
            seriesAssigns[seriesIndex][key].value = getLetterFromIndex(getLetterIndex(serie[key]) - 1).toUpperCase();
            seriesAssigns[seriesIndex][key].rawValue = [getLetterIndex(serie[key]) - 1];
          }
        });
      });
    }
  }
};

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

/** Sort rows
 * @memberof DataTable
 * @param column {number} - the column to sort on
 * @param direction {string} - the direction: `asc` or `desc`
 */
export function sort(rows, column, direction) {
  // eslint-disable-next-line array-callback-return
  rows.sort((a, b) => {
    let ad = a[column],
      bd = b[column];

    if (isNumeric(ad) && isNumeric(bd)) {
      ad = parseFloat(ad);
      bd = parseFloat(bd);

      if (direction === 'asc') {
        return ad - bd;
      }

      // eslint-disable-next-line no-nested-ternary
      return bd < ad ? -1 : bd > ad ? 1 : 0;
    } else if (ad === null) {
      return 1;
    } else if (bd === null) {
      return -1;
    }

    if (direction === 'asc') {
      if (!ad) return bd;
      return ad.localeCompare(bd);
    }

    if (bd) {
      if (!ad) return bd;
      return bd.toString().localeCompare(ad);
    } else if (ad) {
      return ad.localeCompare(bd);
    }
  });
}

export const modifyLaterAssignRawOptions = (state, data) => {
  let { index } = data;
  let { seriesAssigns } = state;
  getAssignDataFields(seriesAssigns).forEach((serie, seriesIndex) => {
    Object.keys(serie).forEach((key) => {
      // Go through each assigns and remove 1 from index if they are bigger than `which`
      if (getLetterIndex(serie[key]) >= index) {
        seriesAssigns[seriesIndex][key].value = getLetterFromIndex(getLetterIndex(serie[key]) + 1).toUpperCase();
        seriesAssigns[seriesIndex][key].rawValue = [getLetterIndex(serie[key]) + 1];
      }
    });
  });
};

export const updateDataWithProperties = (data, newProperties) => ({
  ...data,
  ...newProperties
});

import Line from 'static/icons/templates/Line.svg';
import Bar from 'static/icons/templates/Bar.svg';
import Column from 'static/icons/templates/Column.svg';
import Pie from 'static/icons/templates/Pie.svg';
import Area from 'static/icons/templates/Area.svg';
import StackedArea from 'static/icons/templates/StackedArea.svg';
import Sankey from 'static/icons/templates/Sankey.svg';
import Scatter from 'static/icons/templates/Scatter.svg';
import Unit from 'static/icons/templates/Unit.svg';
import WordCloud from 'static/icons/templates/WordCloud.svg';
import Donut from 'static/icons/templates/Donut.svg';
import StackedBar from 'static/icons/templates/StackedBar.svg';
import StackedColumn from 'static/icons/templates/StackedColumn.svg';
import DependencyWheel from 'static/icons/templates/DependencyWheel.svg';
import Parliament from 'static/icons/templates/Parliament.svg';
import RadialBar from 'static/icons/templates/RadialBar.svg';
import Bubble from 'static/icons/templates/Bubble.svg';
import PolarArea from 'static/icons/templates/PolarArea.svg';
import Waterfall from 'static/icons/templates/Waterfall.svg';
import Candlestick from 'static/icons/templates/Candlestick.svg';
import Boxplot from 'static/icons/templates/Boxplot.svg';
import WindRose from 'static/icons/templates/WindRose.svg';
import SpiderArea from 'static/icons/templates/SpiderArea.svg';
import Dumbbell from 'static/icons/templates/Dumbbell.svg';
import Funnel from 'static/icons/templates/Funnel.svg';
import OHLC from 'static/icons/templates/OHLC.svg';
import SolidGauge from 'static/icons/templates/SolidGauge.svg';
import Heatmap from 'static/icons/templates/Heatmap.svg';
import StreamGraph from 'static/icons/templates/StreamGraph.svg';
import Timeline from 'static/icons/templates/Timeline.svg';
import Treemap from 'static/icons/templates/Treemap.svg';
import PackedBubble from 'static/icons/templates/PackedBubble.svg';
import Choropleth from 'static/icons/templates/Choropleth.svg';
import Categories from 'static/icons/templates/Categories.svg';
import BubbleMap from 'static/icons/templates/BubbleMap.svg';
import Honeycomb from 'static/icons/templates/Honeycomb.svg';
import PatternFill from 'static/icons/templates/PatternFill.svg';
import PointMap from 'static/icons/templates/PointMap.svg';
import TilemapCircle from 'static/icons/templates/TilemapCircle.svg';
import LocationMap from 'static/icons/templates/LocationMap.svg';
import EBRD from 'static/icons/templates/ebrd.svg';

export const templateIcons: Record<string, React.FunctionComponent<React.SVGAttributes<SVGElement>>> = {
  Line: Line,
  Bar: Bar,
  Column: Column,
  Pie: Pie,
  Area: Area,
  StackedArea: StackedArea,
  Sankey: Sankey,
  Scatter: Scatter,
  Unit: Unit,
  WordCloud: WordCloud,
  Donut: Donut,
  StackedBar: StackedBar,
  StackedColumn: StackedColumn,
  DependencyWheel: DependencyWheel,
  Parliament: Parliament,
  RadialBar: RadialBar,
  Bubble: Bubble,
  PolarArea: PolarArea,
  Waterfall: Waterfall,
  Candlestick: Candlestick,
  Boxplot: Boxplot,
  WindRose: WindRose,
  SpiderArea: SpiderArea,
  Dumbbell: Dumbbell,
  Funnel: Funnel,
  OHLC: OHLC,
  SolidGauge: SolidGauge,
  Heatmap: Heatmap,
  StreamGraph: StreamGraph,
  Timeline: Timeline,
  Treemap: Treemap,
  PackedBubble: PackedBubble,
  Choropleth: Choropleth,
  Categories: Categories,
  BubbleMap: BubbleMap,
  Honeycomb: Honeycomb,
  PatternFill: PatternFill,
  PointMap: PointMap,
  TilemapCircle: TilemapCircle,
  LocationMap: LocationMap,
  EBRD: EBRD
};

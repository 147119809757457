import { RootState } from 'redux/store';
import { LocationMapMappingOptions, mapConfigToLocationMapProps } from 'shared/wizard/utils/locationMapMapper';

export const selectLocationMapEditorOptions = (state: RootState) => {
  const { aggregatedOptions, urlParam, tab, showWizard, provider } = state.projectConfig;
  if (provider !== 'locationMap') throw new Error('Provider must be of type location map');
  const isDataTab = showWizard ? urlParam === 'data' : tab === 'data';
  const isPublishTab = showWizard ? urlParam === 'publish' : tab === 'publish';
  const isTypeTab = showWizard ? urlParam === 'type' : tab === 'type';

  const editorMappingOptions: LocationMapMappingOptions = {
    disableText: isDataTab,
    enableFeatureEditing: !isPublishTab || isTypeTab,
    disableInitialAnimation: true,
    disableLayerRemoving: true,
    disableLoading: true,
    disableInteractiveControls: isTypeTab,
    disableInternalReferenceResolutionLogic: true,
    reuseMaps: false,
    mapStyle: { append: true, value: { minHeight: 'unset' } },
    resolution: { value: { type: 'fill' }, override: true },
    interactive: { override: true, value: !isPublishTab },
    disableAttribution: true,
    viewState: { override: false }
  };
  return mapConfigToLocationMapProps(aggregatedOptions, editorMappingOptions);
};

import { cloneDeep } from 'lodash';

export const parseMetadata = (metadata: string) => {
  if (typeof metadata !== 'string') return {};

  try {
    return JSON.parse(metadata);
  } catch (error) {
    return {};
  }
};

const mergingToEBRDOptions = (options: any, data: any) => {
  const { markers = [], viewState = {} } = parseMetadata(data.metadata) || {};

  return {
    ...options,
    markers: Array.isArray(markers) ? markers : [],
    viewState: viewState !== null && typeof viewState === 'object' ? viewState : {}
  };
};

const funcMap: { [key: string]: (options: any, data: any) => any } = {
  ebrd: mergingToEBRDOptions
};

export const mergeDataToOptions = (options: any, data: any) => {
  let type = '';
  if (data.constr.toLowerCase().includes('ebrd')) type = 'ebrd';

  if (!type) return options;

  return funcMap[type](cloneDeep(options), cloneDeep(data));
};

export const mergeNameMetadataToPackageProject = (packageProject: any, projectName: string, metadata: any) => ({
  ...packageProject,
  chart_name: projectName,
  metadata: JSON.stringify(metadata)
});

export const isEBRDPackage = window.location.href.includes('package/edit/ebrd/');

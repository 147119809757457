import { merge } from 'editor/core/highcharts-editor';
import { cloneDeep } from 'lodash';
import store from '../../../redux/store';
import { openPublishModalAction, saveChartAction } from '../actions/chartEditor';
import { getMapCodeValueIndex, toCSV, toMapCSV } from '../utils/chartEditorDataHelper';
import { checkCustomFontsInAggregatedOptions } from 'shared/editor/helpers/saveProjectHelper';

export const getChartConfig = (state) => state.chartEditorPage;

const getAssignDataFields = (options) => {
  let all = [];

  options.forEach((option) => {
    let arr = {};
    Object.keys(option).forEach((key) => {
      if (key === 'hidden' || !option[key].value) return;
      arr[key] = option[key].value;
    });
    all.push(merge({}, arr));
  });

  return all;
};

const getTemplateMeta = (templateMeta) => {
  return [
    {
      templateHeader: templateMeta?.templateHeader,
      templateParseData: templateMeta?.templateParseData,
      templateTitle: templateMeta?.templateTitle
    }
  ];
};

export const getAggregatedChartConfig = (state, chartEditor) => {
  const aggregatedData = state?.aggregatedOptions?.data;
  const constr = chartEditor.constr ?? 'Chart';
  const isMap = constr === 'Map';
  let dataProvider = {
    ...aggregatedData,
    assignDataFields: getAssignDataFields(state.seriesAssigns)
  };

  if (dataProvider.csv) {
    if (isMap) {
      const { mapValueIndex } = getMapCodeValueIndex(state.seriesAssigns);
      dataProvider.csv = toMapCSV(state.dataOptions, mapValueIndex, aggregatedData.itemDelimiter);
    } else dataProvider.csv = toCSV(state.dataOptions, aggregatedData.itemDelimiter);
  }

  // Live data
  const csvUrl = aggregatedData?.csvURL;
  const rowsUrl = aggregatedData?.rowsURL;
  const columnsUrl = aggregatedData?.columnsURL;
  const enablePolling = aggregatedData?.enablePolling;
  const dataRefreshRate = aggregatedData?.dataRefreshRate;
  if (csvUrl || rowsUrl || columnsUrl) {
    // eslint-disable-next-line no-nested-ternary
    const key = csvUrl ? 'csvURL' : rowsUrl ? 'rowsURL' : 'columnsURL';
    dataProvider = {
      liveData: {
        [key]: csvUrl || rowsUrl || columnsUrl
      }
    };
    if (enablePolling && dataRefreshRate) {
      dataProvider.liveData = { ...dataProvider.liveData, dataRefreshRate, enablePolling };
    }
    if (isMap && aggregatedData?.seriesMapping) {
      dataProvider.liveData = {
        ...dataProvider.liveData,
        seriesMapping: aggregatedData.seriesMapping
      };
    }
  }

  // Google sheet
  const googleSheetKey = aggregatedData?.googleSpreadsheetKey;
  if (googleSheetKey) {
    dataProvider = {
      googleSpreadsheet: {
        googleSpreadsheetKey: googleSheetKey,
        googleSpreadsheetRange: aggregatedData.googleSpreadsheetRange,
        enablePolling: aggregatedData.enablePolling,
        dataRefreshRate: aggregatedData.dataRefreshRate
      }
    };
    if (isMap && aggregatedData?.seriesMapping) {
      dataProvider.googleSpreadsheet = {
        ...dataProvider.googleSpreadsheet,
        seriesMapping: aggregatedData.seriesMapping
      };
    }
  }

  let plugins = cloneDeep(state.plugins);
  plugins.cssModules = state.cssModules;
  const response = checkCustomFontsInAggregatedOptions(state.aggregatedOptions);
  if (Array.isArray(response) && response.length > 0) {
    const uniqueLinks = response.filter((link) => !plugins.cssModules.includes(link));
    plugins.cssModules = [...plugins.cssModules, ...uniqueLinks];
  }

  return {
    options: merge({}, state.customizedOptions ?? {}),
    settings: {
      dataProvider,
      plugins,
      template: getTemplateMeta(state.templateMeta[0]),
      templateDataSettings: state.templateDataSettings ?? {},
      constructor: [constr]
    },
    theme: {
      ...(state.themeMeta ?? {}),
      options: merge({}, state.themeOptions ?? {})
    },
    template: {
      ...(state.templateOptions[0] ?? {})
    },
    customCode: chartEditor.customCodeStr
  };
};

export const getAggregatedLocationMapConfig = (state, chartEditor) => {
  const constr = chartEditor.constr ?? 'Map';
  let dataProvider = state.dataOptions;

  let plugins = cloneDeep(state.plugins);
  plugins.cssModules = state.cssModules;

  const options = cloneDeep(state.customizedOptions ?? {});
  return {
    options,
    settings: {
      layerOptions: state.locationMapOptions.layerOptions,
      markerMetadata: state.locationMapOptions.markerMetadata,
      viewStateOptions: state.locationMapOptions.viewStateOptions,
      viewIsLocked: state.viewIsLocked,
      aspectRatio: state.aspectRatio,
      dataProvider,
      plugins,
      template: merge({}, state.templateMeta),
      templateDataSettings: state.templateDataSettings ?? {},
      constructor: [constr]
    },
    theme: {
      ...(state.themeMeta ?? {}),
      options: merge({}, state.themeOptions ?? {})
    },
    template: {
      ...(state.templateOptions[0] ?? {})
    },
    icons: state.icons
  };
};

const createAggregatedConfigMap = {
  locationMap: getAggregatedLocationMapConfig,
  highcharts: getAggregatedChartConfig
};

export const getAggregatedConfig = (state, chartEditor) => {
  return createAggregatedConfigMap[state.provider](state, chartEditor);
};

export const getNavActions = (checkPermission, state, userProps, history, anonymous) => {
  let actions = [];

  if (checkPermission('create_chart') || anonymous) {
    actions.push({
      text: 'Save',
      onClick: () => {
        store.dispatch(
          saveChartAction({
            reload: true,
            callback: () => {
              if (state.projectConfig.published) {
                store.dispatch(openPublishModalAction({}));
              }
            },
            history
          })
        );
      }
    });
  }

  if (anonymous || (checkPermission('publish') && userProps && userProps.features.indexOf('publish') > -1)) {
    actions.push({
      text: 'Publish',
      className: 'publish-btn',
      onClick: () => {
        store.dispatch(openPublishModalAction({}));
      }
    });
  }

  return actions;
};

import middleware, { init } from '../middleware/middleware';
import rootReducer from './reducers/root';
import { configureStore } from '@reduxjs/toolkit';
import { listenerMiddleware } from './listenerMiddleware';

export function initMiddleware() {
  init();
}

const middlewares = [middleware, listenerMiddleware.middleware];

// IMPORTANT: Uncomment these lines when @redux-devtools keeps on crashing, or when working with state between redirections

// if (process.env.NODE_ENV === 'development') {
//   const reduxLogger = require('redux-logger');
//   const logger = reduxLogger.createLogger({ collapsed: true });
//   middlewares.push(logger);
// }

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    }).concat(middlewares)
});

// expose store when run in Cypress
if ((window as any).Cypress) {
  (window as any).store = store;
}

export type RootState = ReturnType<typeof store.getState>;
export default store;

/* eslint-disable no-loop-func */
import { createReducer } from '@reduxjs/toolkit';

import { shuffleArray } from 'editor/core-ui/datatable/highed.datatable.utils';
import templates from 'editor/core/highed.template.plugins';
import { convertHTMLEntities } from 'editor/ui/chartpreview/highed.chartpreview.utils';
import { parseCSV } from 'pages/ChartEditorPage/utils/chartEditorDataHelper';
import {
  clearSeriesMapping,
  deleteColumnFromOptions,
  handleDeletedColumn,
  modifyLaterAssignRawOptions,
  removeRowOrColumn,
  addRowOrColumnToDataOptions,
  addNullToClearColumnCells,
  addMissingColumns,
  removeUnnecesaryRows,
  saveDataAndConfigAsCSV,
  setSeriesMapping,
  sort,
  updateCell,
  updateDataWithProperties
} from 'redux/helpers/projectConfigHelper';
import actionTypes from '../actions/action-types';
import initialState from './initial-state';
import { columnDataType, updateColumnDataType } from 'pages/ChartEditorPage/utils/highchartsPreviewHelper';
import { setTabAction, setUrlParamAction } from '../actions/typedProjectConfig';
const projectConfigReducer = createReducer(initialState.projectConfig, (builder) => {
  builder
    .addCase(actionTypes.projectConfig.set, (state, action) => {
      Object.assign(state, action.data);
    })
    .addCase(actionTypes.projectConfig.updateDataAndConfig, (state, action) => {
      const { row, column, newValue, isMap, skipSeriesMapping } = action.data;
      const { dataOptions } = state;

      updateCell(dataOptions, row, column, newValue);

      dataOptions.forEach((row) => {
        if (column + 1 > row.length) row.push(...Array(column + 1 - row.length));
      });

      if (!skipSeriesMapping) {
        saveDataAndConfigAsCSV(state, isMap);
      }
      state.changeMade = true;
    })
    // Used when editing either one or multiple cells
    .addCase(actionTypes.projectConfig.updateDataAndConfigBulk, (state, action) => {
      let { changes, isMap, skipEmit } = action.data;
      const { dataOptions } = state;
      const allNulls = changes.every((change) => change[3] === null || change[3] === '');
      for (const [row, column, , newValue] of changes) {
        updateCell(dataOptions, row, column, newValue);
      }

      const lastChange = changes[changes.length - 1];
      dataOptions.forEach((row) => {
        if (lastChange[1] + 1 > row.length) row.push(...Array(lastChange[1] + 1 - row.length));
      });

      if (allNulls && !isMap) {
        // User has deleted one or more cells
        // Check that the dataOptions array doesnt end with empty rows
        // If it does, remove them

        removeUnnecesaryRows(dataOptions);

        let columnsDeleted = {};
        let deletedColumn = false;
        changes.forEach((change) => {
          columnsDeleted[change[1]] = (columnsDeleted[change[1]] ?? 0) + 1;
        });
        const keys = Object.keys(columnsDeleted).sort().reverse();
        keys.forEach((columnIndex) => {
          if (columnsDeleted[columnIndex] >= dataOptions.length) {
            // Deleted the whole column, see if there are any mapped values.
            // If so, remove them
            deletedColumn = true;
            if (state.seriesAssigns.length === 1) return;
            handleDeletedColumn(state, {
              index: columnIndex,
              amount: 1,
              skipRemap: true,
              key: skipEmit ? 'columns' : 'series'
            });
          }
        });

        if (deletedColumn) {
          const templateParsing = state.templateMeta?.[0]?.templateParseData;
          const hasSpecialParsing = templateParsing && templates[templateParsing].parseData;
          clearSeriesMapping(state);
          if (!hasSpecialParsing) setSeriesMapping(state);
        }
      }
      if (!skipEmit) saveDataAndConfigAsCSV(state, isMap);
      if (!state.templateMeta?.[0]?.templateParseData) {
        setSeriesMapping(state);
      }

      // Update columnTypes property
      let columnTypes = [];
      if (state.aggregatedOptions?.data?.columnTypes || state.customizedOptions?.data?.columnTypes) {
        columnTypes = updateColumnDataType(state.aggregatedOptions.data.columnTypes, lastChange, dataOptions);
      } else {
        columnTypes = columnDataType(dataOptions);
      }

      const newProperties = { columnTypes };

      // update state with new properties
      state.aggregatedOptions.data = updateDataWithProperties(state.aggregatedOptions.data, newProperties);
      state.customizedOptions.data = updateDataWithProperties(state.customizedOptions.data, newProperties);

      state.changeMade = true;
    })
    .addCase(actionTypes.projectConfig.clearDataGrid, (state, action) => {
      const { skipEmit, isMap } = action.data;
      const { dataOptions } = state;

      dataOptions.forEach((row) => {
        for (let i = 0; i < row.length; i++) {
          row[i] = '';
        }
      });

      if (!skipEmit) {
        saveDataAndConfigAsCSV(state, isMap);
      }
      state.changeMade = true;
    })
    .addCase(actionTypes.projectConfig.movedColumns, (state, action) => {
      const { movedCells, finalIndex, isMap, skipEmit } = action.data;
      const { dataOptions } = state;

      dataOptions.forEach((row) => {
        shuffleArray(row, movedCells[0], movedCells.length, finalIndex);
      });

      if (!skipEmit) saveDataAndConfigAsCSV(state, isMap);
      state.changeMade = true;
    })
    .addCase(actionTypes.projectConfig.movedRows, (state, action) => {
      const { movedCells, finalIndex, isMap } = action.data;
      const { dataOptions } = state;

      shuffleArray(dataOptions, movedCells[0], movedCells.length, finalIndex);
      saveDataAndConfigAsCSV(state, isMap);
      state.changeMade = true;
    })
    .addCase(actionTypes.projectConfig.addDataSeries, (state, action) => {
      const { index, amount, source } = action.data;
      const { dataOptions } = state;
      const emptyRow = [...Array(state.dataOptions[0].length)];

      // Add missing columns compared to the chart's initial data and data grid min columns
      addMissingColumns(dataOptions, index);
      addRowOrColumnToDataOptions(source, dataOptions, index, amount, emptyRow);

      if (source === 'ContextMenu.columnLeft' || source === 'ContextMenu.columnRight') {
        // For all seriesAssigns after this index, we want to go through and +1 to the data fields.
        modifyLaterAssignRawOptions(state, { index });
      }

      state.changeMade = true;
    })
    .addCase(actionTypes.projectConfig.clearColumn, (state, action) => {
      const { index, amount, skipEmit } = action.data;
      let { dataOptions } = state;

      handleDeletedColumn(state, { ...action.data, skipRemap: true });

      if (!skipEmit) clearSeriesMapping(state);
      addNullToClearColumnCells(dataOptions, index, amount);

      if (!skipEmit) {
        saveDataAndConfigAsCSV(state);
        setSeriesMapping(state);
      }
      state.changeMade = true;
      return state;
    })
    .addCase(actionTypes.projectConfig.deleteSeries, (state, action) => {
      const { index, amount, source, skipEmit } = action.data;
      let { dataOptions } = state;
      if (!skipEmit) clearSeriesMapping(state);
      removeRowOrColumn(source, dataOptions, index, amount);
      removeUnnecesaryRows(dataOptions);

      if (!skipEmit) {
        if (!state.templateMeta?.[0]?.templateParseData) {
          setSeriesMapping(state);
        }
        saveDataAndConfigAsCSV(state);
      }
      state.changeMade = true;
      return state;
    })
    .addCase(actionTypes.projectConfig.sortData, (state, action) => {
      const { column, sortOrder, isMap } = action.data;
      const { dataOptions } = state;

      const headers = dataOptions.shift();
      sort(dataOptions, column, sortOrder);
      dataOptions.unshift(headers);
      saveDataAndConfigAsCSV(state, isMap);
      state.changeMade = true;
    })
    .addCase(actionTypes.projectConfig.handleDeletedColumn, (state, action) => {
      const { seriesAssigns } = state;

      if (seriesAssigns.length === 1) return state;

      handleDeletedColumn(state, action.data);
      state.changeMade = true;
    })
    .addCase(actionTypes.projectConfig.deleteColumnFromOptions, (state, action) => {
      const { customizedOptions, aggregatedOptions } = state;
      const { key, index } = action.data;

      deleteColumnFromOptions(customizedOptions, aggregatedOptions, key, index);
      state.changeMade = true;
    })
    .addCase(actionTypes.projectConfig.updateSeriesMapping, (state, action) => {
      const { newAssigns, isMap } = action.data;
      if (newAssigns) state.seriesAssigns = newAssigns;
      saveDataAndConfigAsCSV(state, isMap);
      setSeriesMapping(state);
      state.changeMade = true;
    })
    .addCase(actionTypes.projectConfig.changeProjectName, (state, action) => {
      state.projectName = action.data.projectName;
    })
    .addCase(actionTypes.projectConfig.registerChangeMade, (state) => {
      state.changeMade = true;
    })
    .addCase(actionTypes.projectConfig.unsetChangeMade, (state) => {
      state.changeMade = false;
    })
    .addCase(actionTypes.projectConfig.toggleInspired, (state, action) => {
      state.toggleInspired = action.data;
    })
    .addCase(actionTypes.projectConfig.toggleUnsavedChangesModal, (state, action) => {
      state.showUnsavedChangesModal = action.data;
    })
    .addCase(actionTypes.projectConfig.toggleUnpublishModal, (state, action) => {
      state.showUnpublishModal = action.data;
    })
    .addCase(actionTypes.projectConfig.reset, () => {
      return initialState.projectConfig;
    })
    .addCase(actionTypes.projectConfig.closeWizard, (state, action) => {
      state.showWizard = action.data || false;
      state.loadingEditor = false;
    })
    .addCase(actionTypes.projectConfig.resetTheme, (state) => {
      state.themeOptions = {
        options: {}
      };
    })
    .addCase(actionTypes.projectConfig.loadData, (state, action) => {
      const { projectData } = action.data;
      const dataProvider = projectData.settings.dataProvider;
      const isMap = projectData.options && (projectData.options.chart?.map || projectData.options.plotOptions?.map);

      if (dataProvider.googleSpreadsheet) {
        state.customizedOptions.data = projectData.settings.dataProvider.googleSpreadsheet;
      } else if (dataProvider.liveData) {
        state.customizedOptions.data = projectData.settings.dataProvider.liveData;
      } else if (dataProvider.csv) {
        state.customizedOptions.data = dataProvider;
        dataProvider.csv = convertHTMLEntities(dataProvider.csv);
        const csv = dataProvider.csv.replace('&amp;', '&');
        state.dataOptions = parseCSV(csv, dataProvider.itemDelimiter);
        const hasTemplateParseData = state.templateMeta?.[0]?.templateParseData;
        if (hasTemplateParseData) {
          state.customizedOptions.data = state.aggregatedOptions.data = { csv: csv };
        }
        if (dataProvider?.columnTypes) {
          state.customizedOptions.data.columnTypes = state.aggregatedOptions.columnTypes = dataProvider?.columnTypes;
        }
        saveDataAndConfigAsCSV(state, isMap);
        if (!hasTemplateParseData) {
          setSeriesMapping(state);
        }
        state.changeMade = true;
      }
    })
    .addCase(setTabAction, (state, action) => {
      state.tab = action.payload.tab;
    })
    .addCase(setUrlParamAction, (state, action) => {
      state.urlParam = action.payload;
    });
});

export default projectConfigReducer;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { setAction as setPackageConfigAction } from 'pages/PackagesPage/actions/packagesPage';

type StockCustomizeProps = {
  active?: boolean;
};

type Stock = {
  symbol: string;
  name: string;
};

const stocks: Stock[] = [
  {
    symbol: 'AAPL',
    name: 'Apple Inc (AAPL)'
  },
  {
    symbol: 'MSFT',
    name: 'Microsoft Corp (MSFT)'
  },
  {
    symbol: 'TSLA',
    name: 'Tesla Inc (TSLA)'
  }
];

const StockCustomize: React.FC<StockCustomizeProps> = () => {
  const dispatch = useDispatch();
  const { stockSymbol } = useSelector((state: RootState) => state.packagesPage);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredStocks, setFilteredStocks] = useState<Stock[]>([]);

  useEffect(() => {
    const filtered = stocks.find((stock) => stock.symbol === stockSymbol);
    if (filtered) setSearchTerm(filtered.name);
    else dispatch(setPackageConfigAction({ stockSymbol: stocks[0].symbol }));
  }, [stockSymbol]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.toLowerCase();
    setSearchTerm(input);
    const filtered = stocks.filter((stock) => stock.name.toLowerCase().includes(input));
    setFilteredStocks(filtered);
  };

  const onClickStock = (stock: Stock) => {
    setFilteredStocks([]);
    setSearchTerm(stock.name);
    dispatch(setPackageConfigAction({ stockSymbol: stock.symbol }));
  };

  return (
    <>
      <label
        style={{
          fontSize: '14px',
          fontWeight: 'bold',
          marginBottom: '0px',
          display: 'block'
        }}
      >
        Stock
      </label>
      <input
        type="text"
        id="stockSearch"
        placeholder="Search by stock name"
        value={searchTerm}
        onChange={handleSearch}
        style={{
          width: '100%',
          padding: '8px 10px',
          borderRadius: '5px',
          border: '1px solid #ccc',
          fontSize: '14px'
        }}
      />
      <ul
        style={{
          listStyleType: 'none',
          padding: '0',
          margin: '0',
          border: filteredStocks.length > 0 ? '1px solid #ccc' : 'none',
          borderRadius: '5px',
          maxHeight: '200px',
          overflowY: 'auto',
          boxShadow: filteredStocks.length > 0 ? '0px 4px 8px rgba(0, 0, 0, 0.1)' : 'none'
        }}
      >
        {filteredStocks.map((stock) => (
          <li
            key={stock.symbol}
            onClick={() => onClickStock(stock)}
            style={{
              padding: '10px',
              borderBottom: '1px solid #eee',
              cursor: 'pointer',
              backgroundColor: '#fff',
              transition: 'background-color 0.3s ease'
            }}
            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#fff')}
          >
            {stock.name}
          </li>
        ))}
      </ul>
    </>
  );
};

export default StockCustomize;

import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsPreviewMap, selectLocationMap } from 'redux/reducers/locationMap/instanceReducer';
import { selectViewBoxAspectRatio, setViewBoxResolution } from 'redux/reducers/locationMap/viewStateReducer';
import useResizeObserver from 'use-resize-observer';

export const ViewBox = () => {
  const dispatch = useDispatch();
  const locationMap = useSelector(selectLocationMap);
  const aspectRatio = useSelector(selectViewBoxAspectRatio);
  const isPreview = useSelector(selectIsPreviewMap);
  const viewBoxRef = useRef<HTMLDivElement | null>(null);

  const map = locationMap && locationMap.getMapEngine();
  const [mapAspectRatio, setMapAspectRatio] = useState(map ? map.transform.width / map.transform.height : 1);

  useResizeObserver({
    ref: viewBoxRef.current,
    onResize: () => {
      if (viewBoxRef.current) {
        const { height, width } = viewBoxRef.current.getBoundingClientRect();
        if (height !== 0 && width !== 0) {
          dispatch(setViewBoxResolution({ height: height, width: width }));
        }
      }
      if (!map) return;
      const height = map.transform.height;
      const width = map.transform.width;
      setMapAspectRatio(width / height);
    }
  });

  useEffect(() => {
    if (!map || !viewBoxRef.current) return;
    const mapContainer = map.getContainer();
    const viewBoxContainer = viewBoxRef.current;
    if (isPreview) {
      const controlContainer = viewBoxContainer.querySelector('div.maplibregl-control-container');
      if (controlContainer) mapContainer.appendChild(controlContainer);

      const childrenContainer = viewBoxContainer.querySelector('[mapboxgl-children]');
      if (childrenContainer) mapContainer.appendChild(childrenContainer);
    } else {
      const controlContainer = mapContainer.querySelector('div.maplibregl-control-container');
      const childrenContainer = mapContainer.querySelector('[mapboxgl-children]');

      if (controlContainer && !viewBoxContainer.contains(controlContainer)) {
        viewBoxContainer.appendChild(controlContainer);
      }

      if (childrenContainer && !viewBoxContainer.contains(childrenContainer)) {
        viewBoxContainer.appendChild(childrenContainer);
      }
    }
    return () => {
      if (!viewBoxContainer) return;
      const controlContainer = viewBoxContainer.querySelector('div.maplibregl-control-container');
      if (controlContainer) mapContainer.appendChild(controlContainer);

      const childrenContainer = viewBoxContainer.querySelector('[mapboxgl-children]');
      if (childrenContainer) mapContainer.appendChild(childrenContainer);
    };
  }, [map]);

  useEffect(() => {
    if (!map) return;
    const onResize = () => {
      setMapAspectRatio(map.transform.width / map.transform.height);
    };
    map.on('resize', onResize);
    return () => {
      map.off('resize', onResize);
    };
  }, [map]);

  if (!map) return <></>;

  const mapContainer = map.getContainer();
  const computedStyling = aspectRatio > mapAspectRatio ? { width: '90%' } : { height: '90%' };

  return createPortal(
    <div
      id="viewbox"
      ref={viewBoxRef}
      style={{
        ...computedStyling,
        aspectRatio: aspectRatio,
        position: 'absolute',
        top: '50%',
        left: '50%',
        pointerEvents: 'none',
        transform: 'translate(-50%, -50%)',
        border: '#00000026 dashed',
        boxShadow: '0px 0px 0px 100vh #9d9d9d8a'
      }}
    />,
    mapContainer
  );
};

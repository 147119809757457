import { point } from '@turf/helpers';
import { cloneDeep } from 'lodash';
import { select, put, takeEvery, all, call } from 'redux-saga/effects';
import { getProjectConfig } from '../../../../redux/selectors/projectConfig';
import { setAction as setProjectConfigAction } from 'redux/actions/projectConfig';
import {
  LabelAnchor,
  MarkerIconChangePayload,
  CustomMarkerConfig,
  MarkerLabelChangePayload
} from '@visual-elements/location-map';
import { GenericPayload } from '../ChartEditorPointMap';
import actionTypes from '../../../../redux/actions/action-types';
import { updateAggregated } from '../ChartEditor';
import { CSSProperties } from 'react';
import { ProjectConfigLocationMapProps } from '../../../Editor/reducers/locationMapConfigTypes';
import { useSectionStore } from '../../../../shared/editor/generic/sectionStore';
import { LocationMapInstanceState } from '../../../../redux/reducers/locationMap/instanceReducer';
import { RootState } from 'redux/store';
import { fitMapToFeatures } from 'redux/reducers/locationMap/viewStateReducer';

export type LabelPreset = {
  id: string;
  name: string;
  style: CSSProperties;
  connector: CustomMarkerConfig['connector'];
  anchorStyleOverrides: Partial<Record<LabelAnchor, CSSProperties>>;
};

export type IconPreset = {
  id: string;
  name: string;
  keywords: string[];
  size: number;
  style?: CSSProperties;
} & { type: 'svg'; svg: string };

export type MarkerPreset = {
  id: string;
  name: string;
  editorOptions: {
    rotatable: boolean;
    isGrouped: boolean;
  };
  label:
    | {
        enabled: boolean;
        defaultAnchor: LabelAnchor;
        anchors: LabelAnchor[];
        defaultPresetId: string;
        presets: LabelPreset[];
      }
    | { enabled: false };
  icon:
    | {
        enabled: boolean;
        defaultPresetId: string;
        presets: IconPreset[];
      }
    | { enabled: false };
};

export type MarkerMetadata = {
  markerPresetId: string;
  markerPresetName: string;
  iconPresetId?: string;
  iconPresetName?: string;
  iconSrc?: string;
  labelPresetId?: string;
  labelPresetName?: string;
};

export type LocationMapMarkerVariant = 'PinMarker' | 'LabelMarker' | 'TextMarker';

type AddLocationMapMarkerPayload = {
  data: { id: string; type: LocationMapMarkerVariant; location: number[]; label: string };
} & GenericPayload;

export function* addLocationMapMarker(params: AddLocationMapMarkerPayload) {
  try {
    const { customizedOptions, locationMapOptions }: ProjectConfigLocationMapProps = yield select(getProjectConfig);
    const { locationMap }: LocationMapInstanceState = yield select((state) => state.locationMapInstance);
    const { viewIsLocked }: { viewIsLocked: boolean } = yield select((state: RootState) => state.viewState);
    const markerPreset = Object.values(locationMapOptions.markerPresets)[0];
    if (!markerPreset) return;

    const newCustomizedOptions = cloneDeep(customizedOptions);
    const newLocationMapOptions = cloneDeep(locationMapOptions);

    const [newMarker, markerMetaData] = getMarkerFromPreset(params.data.id, params.data.label, markerPreset);
    newCustomizedOptions.markers ??= [];
    newCustomizedOptions.markers.unshift({
      ...newMarker,
      data: { type: 'static', content: point(params.data.location) }
    });
    newLocationMapOptions.markerMetadata[newMarker.id] = markerMetaData;
    yield call(useSectionStore.getState().setSelectedSection, newMarker.id);

    yield put(
      setProjectConfigAction({
        customizedOptions: newCustomizedOptions,
        locationMapOptions: newLocationMapOptions,
        changeMade: true
      })
    );

    yield call(updateAggregated);

    if (!viewIsLocked) {
      const waitForPositionSet = () =>
        new Promise((resolve, reject) => {
          if (!locationMap) reject();
          const handler = (id: string) => {
            if (id === newMarker.id) {
              locationMap?.off('markerPositionSet', handler);
              resolve(id);
            }
          };

          locationMap?.on('markerPositionSet', handler);
        });
      yield call(waitForPositionSet);
      yield put(fitMapToFeatures());
    }
  } catch (error) {
    console.log(error);
  }
}

export function getMarkerFromPreset(
  id: string,
  label: string,
  preset: MarkerPreset
): [Omit<CustomMarkerConfig, 'data'>, MarkerMetadata] {
  const markerMetaData: MarkerMetadata = {
    markerPresetId: preset.id,
    markerPresetName: preset.name
  };

  const buildIcon: () => CustomMarkerConfig['icon'] = () => {
    if (preset.icon.enabled) {
      const iconPresetId = preset.icon.defaultPresetId;
      const iconPreset = preset.icon.presets.find((x) => x.id === iconPresetId);
      if (!iconPreset) return { enabled: false };

      markerMetaData.iconPresetId = iconPreset.id;
      markerMetaData.iconPresetName = iconPreset.name;
      markerMetaData.iconSrc = iconPreset.svg;
      return {
        enabled: true,
        type: 'svg',
        size: iconPreset.size,
        src: iconPreset.svg,
        style: iconPreset.style
      };
    }
    return {
      enabled: false
    };
  };

  const buildLabel: () => CustomMarkerConfig['label'] = () => {
    if (preset.label.enabled) {
      const labelPresetId = preset.label.defaultPresetId;
      const labelPreset = preset.label.presets.find((x) => x.id === labelPresetId);
      if (!labelPreset) return { enabled: false };
      markerMetaData.labelPresetId = labelPreset.id;
      markerMetaData.labelPresetName = labelPreset.name;
      const anchorStyleOverrides = labelPreset.anchorStyleOverrides[preset.label.defaultAnchor];
      return {
        enabled: true,
        anchor: preset.label.defaultAnchor,
        text: label,
        style: anchorStyleOverrides ? { ...labelPreset.style, ...anchorStyleOverrides } : labelPreset.style
      };
    }
    return {
      enabled: false
    };
  };

  const buildConnector: () => CustomMarkerConfig['connector'] = () => {
    if (!preset.label.enabled) return { enabled: false };
    const presetId = preset.label.defaultPresetId;
    const labelPreset = preset.label.presets.find((x) => x.id === presetId);
    if (!labelPreset || !labelPreset.connector?.enabled) return { enabled: false };
    return {
      enabled: true,
      style: labelPreset.connector.style
    };
  };

  return [
    {
      id: id,
      icon: buildIcon(),
      connector: buildConnector(),
      editorOptions: { enableRotation: preset.editorOptions.rotatable, isGrouped: preset.editorOptions.isGrouped },
      label: buildLabel(),
      rotation: 0
    },
    markerMetaData
  ];
}

type RemoveLocationMapMarkerPayload = {
  data: { id: string };
} & GenericPayload;

export function* removeLocationMapMarker(params: RemoveLocationMapMarkerPayload) {
  try {
    const { customizedOptions, locationMapOptions }: ProjectConfigLocationMapProps = yield select(getProjectConfig);

    const newCustomizedOptions = cloneDeep(customizedOptions);
    const newLocationMapOptions = cloneDeep(locationMapOptions);

    if (!newCustomizedOptions.markers) return;

    const customizeIndex = newCustomizedOptions.markers.findIndex((x) => x.id === params.data.id);
    if (customizeIndex < 0) return;
    newCustomizedOptions.markers.splice(customizeIndex, 1);

    delete newLocationMapOptions.markerMetadata[params.data.id];

    yield put(
      setProjectConfigAction({
        customizedOptions: newCustomizedOptions,
        locationMapOptions: newLocationMapOptions,
        changeMade: true
      })
    );
    yield call(updateAggregated);
  } catch (e) {
    console.log(e);
  }
}

type InlineUpdateMarkerPayload = {
  data:
    | {
        id: string;
        target: 'icon';
        payload: MarkerIconChangePayload;
      }
    | {
        id: string;
        target: 'label';
        payload: MarkerLabelChangePayload;
      };
} & GenericPayload;

export function* inlineUpdateMarker(params: InlineUpdateMarkerPayload) {
  try {
    // Only the icon position triggers changes to the data config,
    // So only get and update customized options here
    const { customizedOptions }: ProjectConfigLocationMapProps = yield select(getProjectConfig);
    const newCustomizedOptions = cloneDeep(customizedOptions);

    if (!newCustomizedOptions.markers) return;

    const index = newCustomizedOptions.markers.findIndex((x) => x.id === params.data.id);
    if (index < 0) return;
    const marker = newCustomizedOptions.markers[index];

    if (params.data.target === 'label' && marker.label?.enabled) {
      const label = marker.label ?? {};
      switch (params.data.payload.type) {
        case 'rotation':
          marker.rotation = params.data.payload.rotation;
          break;
        case 'offset':
          label.anchor = 'manual';
          label.offset = params.data.payload.offset;
          break;
        case 'resize':
          label.style ??= {};
          label.style.width = params.data.payload.width;
          label.offset = params.data.payload.offset;
          break;
        case 'title':
          label.text = params.data.payload.title;
          break;
        case 'position':
          if (marker.data.type === 'static') {
            marker.data.content = point(params.data.payload.position);
          }
          break;
      }
      marker.label = label;
    } else if (marker.icon?.enabled && (marker.icon.type === 'image' || marker.icon.type === 'svg')) {
      switch (params.data.payload.type) {
        case 'rotation':
          marker.rotation = params.data.payload.rotation;
          break;
        case 'size':
          marker.icon.size = params.data.payload.size;
          break;
        case 'position':
          if (marker.data.type === 'static') {
            marker.data.content = point(params.data.payload.position);
          }
      }
    }
    yield put(
      setProjectConfigAction({
        customizedOptions: newCustomizedOptions,
        changeMade: true
      })
    );

    yield call(updateAggregated);
  } catch (err) {
    console.log(err);
  }
}

type updateLocationMapMarkerPayload = {
  data: { markerId: string } & (
    | { field: 'text'; text: string }
    | { field: 'labelPlacement'; placement: LabelAnchor }
    | { field: 'icon'; iconPresetId: string }
    | { field: 'labelPreset'; labelPresetId: string }
    | { field: 'markerPreset'; markerPresetId: string }
  );
} & GenericPayload;

export function* updateLocationMapMarker(params: updateLocationMapMarkerPayload) {
  try {
    const { customizedOptions, locationMapOptions, aggregatedOptions }: ProjectConfigLocationMapProps = yield select(
      getProjectConfig
    );

    const newCustomizedOptions = cloneDeep(customizedOptions);
    const newLocationMapOptions = cloneDeep(locationMapOptions);

    const { markerPresets, markerMetadata } = newLocationMapOptions;
    if (!newCustomizedOptions.markers) return;

    const marker = newCustomizedOptions.markers.find((x) => x.id === params.data.markerId);
    if (!marker) return;
    const aggregatedMarker = aggregatedOptions.markers.find((x) => x.id === params.data.markerId);
    if (!aggregatedMarker) throw new Error('aggregated marker should exist');

    const metadata = markerMetadata[marker.id];
    const currentMarkerPreset = markerPresets.find((x) => x.id === metadata.markerPresetId);
    if (marker.label?.enabled && params.data.field === 'text') marker.label.text = params.data.text;

    if (marker.label?.enabled && params.data.field === 'labelPreset') {
      if (currentMarkerPreset && currentMarkerPreset.label.enabled) {
        const labelPresetId = params.data.labelPresetId;
        const newLabelPreset = currentMarkerPreset.label.presets.find((x) => x.id === labelPresetId);
        if (newLabelPreset) {
          metadata.labelPresetId = newLabelPreset.id;
          metadata.labelPresetName = newLabelPreset.name;
          const anchorStyleOverrides = newLabelPreset.anchorStyleOverrides[aggregatedMarker.label.anchor];
          marker.label.style = { ...newLabelPreset.style, ...anchorStyleOverrides };
        }
      }
    }
    if (marker.icon?.enabled && params.data.field === 'icon') {
      if (currentMarkerPreset && currentMarkerPreset.icon.enabled) {
        const iconPresetId = params.data.iconPresetId;
        const newIconPreset = currentMarkerPreset.icon.presets.find((x) => x.id === iconPresetId);
        if (newIconPreset) {
          metadata.iconPresetId = newIconPreset.id;
          metadata.iconPresetName = newIconPreset.name;
          metadata.iconSrc = newIconPreset.svg;
          marker.icon = {
            enabled: true,
            type: newIconPreset.type,
            src: newIconPreset.svg,
            style: newIconPreset.style,
            size: newIconPreset.size
          };
        }
      }
    }

    if (params.data.field === 'markerPreset') {
      const newMarkerPresetId = params.data.markerPresetId;

      const newMarkerPreset = markerPresets.find((x) => x.id === newMarkerPresetId);
      if (!newMarkerPreset) return;

      const markerIndex = newCustomizedOptions.markers?.findIndex((x) => x.id === marker.id);
      const [newMarker, markerMetaData] = getMarkerFromPreset(
        marker.id,
        marker.label?.enabled ? marker.label.text : 'title',
        newMarkerPreset
      );
      newCustomizedOptions.markers[markerIndex] = { ...newMarker, data: marker.data };
      newLocationMapOptions.markerMetadata[newMarker.id] = markerMetaData;
    }
    if (marker.label?.enabled && params.data.field === 'labelPlacement') {
      marker.label.anchor = params.data.placement;
      if (currentMarkerPreset && currentMarkerPreset.label.enabled) {
        const currentLabelPreset = currentMarkerPreset.label.presets.find((x) => x.id === metadata.labelPresetId);
        if (currentLabelPreset && currentLabelPreset.anchorStyleOverrides[params.data.placement]) {
          const anchorStyleOverrides = currentLabelPreset.anchorStyleOverrides[marker.label.anchor];

          marker.label.style = {
            ...currentLabelPreset.style,
            ...anchorStyleOverrides
          };
        }
      }
    }

    yield put(
      setProjectConfigAction({
        customizedOptions: newCustomizedOptions,
        locationMapOptions: newLocationMapOptions,
        changeMade: true
      })
    );
    yield call(updateAggregated);
  } catch (e) {
    console.log(e);
  }
}

type FocusLocationMapMarkerPayload = GenericPayload & {
  data: {
    id: number;
  };
};

export function* focusLocationMapMarker(params: FocusLocationMapMarkerPayload) {
  try {
    const { locationMap, mapDefined }: LocationMapInstanceState = yield select((state) => state.locationMapInstance);
    if (!mapDefined) {
      console.log("Map is not defined. Can't focus feature");
      return;
    }
    locationMap.selectFeature({ id: params.data.id.toString(), type: 'marker' });
  } catch (err) {
    console.log(err);
  }
}

export function* watchAddLocationMapMarker() {
  yield takeEvery(actionTypes.locationMap.addLocationMapMarker, addLocationMapMarker);
}

export function* watchRemoveLocationMapMarker() {
  yield takeEvery(actionTypes.locationMap.removeLocationMapMarker, removeLocationMapMarker);
}

export function* watchInlineUpdateMarker() {
  yield takeEvery(actionTypes.locationMap.inlineUpdateMarker, inlineUpdateMarker);
}

export function* watchUpdateLocationMapMarker() {
  yield takeEvery(actionTypes.locationMap.updateLocationMapMarker, updateLocationMapMarker);
}

export function* watchFocusLocationMapMarker() {
  yield takeEvery(actionTypes.locationMap.focusLocationMapMarker, focusLocationMapMarker);
}

export default function* rootSaga() {
  yield all([
    watchAddLocationMapMarker(),
    watchRemoveLocationMapMarker(),
    watchInlineUpdateMarker(),
    watchFocusLocationMapMarker(),
    watchUpdateLocationMapMarker()
  ]);
}

import { isArray } from 'lodash';
import { all, call, put } from 'redux-saga/effects';
import { setAction as setProjectConfigAction } from '../../../../redux/actions/projectConfig';
import { updateAggregated } from '../ChartEditor';
import { GenericPayload } from '../ChartEditorPointMap';
import { loadRelevantModules } from 'pages/ChartEditorPage/utils/chartEditorHelper';
import { setAction as setChartAction } from 'pages/ChartEditorPage/actions/chartEditor';
import { getTemplateData } from '../ChartEditorTemplates';
import { LocationMapDataFeatures } from './types';
import { staticMarkerPresets } from './LocationMapMarkerPresetStore';
import { MarkerPreset } from './LocationMapMarker';
import { defaultLocationMapCustomOptions } from '../../meta/DefaultOptions';
import { LocationMapCustomizedOptions, LocationMapState } from '../../../Editor/reducers/locationMapConfigTypes';
import { setKeyFrames } from '../../../../redux/reducers/locationMap/animationReducer';
import { setViewBoxAspectRatio, setViewIsLocked } from '../../../../redux/reducers/locationMap/viewStateReducer';

type LoadProjectLocationMapPayload = GenericPayload & {
  data: {
    project: any;
  };
};

export function* loadProjectLocationMap(params: LoadProjectLocationMapPayload) {
  const { project: projectData } = params.data;

  yield put(
    setProjectConfigAction({
      provider: 'locationMap'
    })
  );

  const pluginConfig = loadRelevantModules(projectData);

  let templateOptions = [{}];
  let templateMeta = [] as any;

  if (projectData.template) {
    if (isArray(projectData.template)) templateOptions = projectData.template;
    else templateOptions = [projectData.template];
  }

  if (projectData.settings?.template) {
    templateMeta = [projectData.settings?.template[0]];
  }

  let templateDataSettings = {};

  if (projectData.settings?.templateDataSettings) {
    templateDataSettings = projectData.settings.templateDataSettings;
  }

  let customizedOptions: LocationMapCustomizedOptions;
  if (projectData.options) customizedOptions = projectData.options;
  else customizedOptions = defaultLocationMapCustomOptions;

  let themeOptions = {} as any;
  if (typeof projectData.theme !== 'undefined') {
    themeOptions = projectData.theme;
  }

  const dataOptions: LocationMapDataFeatures = projectData.settings.dataProvider;
  const markerPresets = getMarkerPresets();

  const locationMapOptions: LocationMapState = {
    layerOptions: projectData.settings.layerOptions,
    markerMetadata: projectData.settings.markerMetadata ?? {},
    markerPresets: markerPresets
  };

  yield put(
    setProjectConfigAction({
      customizedOptions,
      templateOptions,
      themeOptions: (themeOptions ?? {}).options ?? {},
      showWizard: false,
      templateDataSettings,
      pluginConfig,
      plugins: projectData?.settings?.plugins ?? {},
      cssModules: projectData?.settings?.plugins?.cssModules ?? [],
      dataOptions,
      templateMeta,
      locationMapOptions: locationMapOptions,
      icons: projectData.icons
    })
  );

  if (customizedOptions.animation) {
    const keyFrames =
      (customizedOptions.animation.initialViewState ? 1 : 0) + (customizedOptions.animation.keyFrames?.length ?? 0);
    yield put(setKeyFrames({ amount: keyFrames }));
  }

  if (projectData.settings.viewIsLocked) {
    yield put(setViewIsLocked(true));
  }

  if (projectData.settings.aspectRatio) {
    yield put(setViewBoxAspectRatio(projectData.settings.aspectRatio));
  }

  yield call(updateAggregated, true);

  let chosenWizardTemplate = false;
  if (templateMeta) {
    chosenWizardTemplate = yield call(getTemplateData, {
      data: {
        template: templateMeta
      }
    });
  }

  const projectConfigOptions = { loadingEditor: false };

  yield all([
    put(setProjectConfigAction(projectConfigOptions)),
    put(
      setChartAction({
        chosenWizardTemplate,
        constr: 'Map',
        isMap: true
      })
    )
  ]);
}

// Temporary function until we fetch from api instead
export function getMarkerPresets(): MarkerPreset[] {
  return staticMarkerPresets;
}

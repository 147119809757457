import { cloneDeep, isArray, isObject, set } from 'lodash';
import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { getSeriesType } from 'shared/wizard/utils/seriesHelper';
import { clean, getLetterFromIndex, getLetterIndex, merge } from '../../../editor/core/highcharts-editor';
import templates from '../../../editor/core/highed.template.plugins';
import actionTypes from '../../../redux/actions/action-types';
import { setAction as setProjectConfigAction, updateSeriesMappingAction } from '../../../redux/actions/projectConfig';
import { getProjectConfig } from '../../../redux/selectors/projectConfig';
import { setAction } from '../../LayoutEditorPage/actions/layoutEditor';
import { loadGoogleSheetAction, loadLiveDataAction, setAction as setChartAction } from '../actions/chartEditor';
import DefaultData from '../meta/data/DefaultData';
import { getChartConfig } from '../selectors/chartEditor';
import { getSeriesType as getChartSeriesType } from 'editor/core/highed.chart-helpers';

import {
  parseDataNameForSeriesMapping,
  saveDataAndConfigAsCSV,
  setSeriesMapping
} from 'redux/helpers/projectConfigHelper';
import {
  checkSections,
  getAllMergedLabelAndData,
  getCSVFromText,
  guessDelimiter,
  parseCSV,
  toCSV,
  toMapCSV,
  getDateFormat,
  generateTypeObject,
  getMapCodeValueIndex,
  generateNewAssignsBasedOnMapCode
} from '../utils/chartEditorDataHelper';
import { parseDataForValidColumns, parseDataForValueColumn } from '../utils/chartEditorDataImportHelper';
import {
  getMapKey,
  getAcceptedValues,
  getMatchingKey,
  getRandomMapData,
  isPatternFill,
  isPointMap,
  isPointMapFromConfig,
  getMapJoinByKeyValue,
  getMapCodeErrorFields,
  parseCodesForCaseSensitivity,
  fetchCsv,
  fetchGoogleSheetData
} from '../utils/chartEditorMapHelper';
import assignDefaults from './../../../editor/meta/highed.meta.assign.defaults';
import chartTypes from './../../../editor/meta/highed.meta.charttype';
import { updateAggregated } from './ChartEditor';
import { columnDataType } from '../utils/highchartsPreviewHelper';

const defaultMap = 'countries/us/us-all';

export function* deleteSeries(params) {
  let { length } = params.data;
  const config = yield select(getProjectConfig);
  const customizedOptions = merge({}, config.customizedOptions);

  if (customizedOptions && customizedOptions.series) {
    customizedOptions.series = customizedOptions.series.slice(0, length);
    yield put(
      setProjectConfigAction({
        customizedOptions
      })
    );
    yield call(updateAggregated, true);
  }
}

export function* deleteSingleSeries(params) {
  let { index } = params.data;
  const config = yield select(getProjectConfig);
  const customizedOptions = merge({}, config.customizedOptions);

  if (customizedOptions.series && customizedOptions.series[index]) {
    customizedOptions.series.splice(index, 1);
    // chartTemplates.deleteTemplateOption(index);
  }

  yield put(
    setProjectConfigAction({
      customizedOptions
    })
  );

  yield call(updateAggregated, true);
}

export function* addBlankSeries(params) {
  try {
    let { index, type, extra, skipEmit, customizedOptions, seriesAssigns } = params.data;

    if (!customizedOptions) {
      const config = yield select(getProjectConfig);
      customizedOptions = merge({}, config.customizedOptions);
    }

    if (!extra) {
      extra = {};
    }

    if (!customizedOptions.series) {
      customizedOptions.series = [];
    }

    if (!customizedOptions.series[index]) {
      customizedOptions.series[index] = merge(
        {
          data: [],
          turboThreshold: 0,
          compare: undefined
        },
        extra
      );
    }

    if (type) customizedOptions.series[index].type = type;

    if (extra) {
      customizedOptions.series[index] = merge(customizedOptions.series[index], extra);
    }

    let updatedProjectOptions = {};
    if (seriesAssigns) {
      let newOptions = generateTypeObject(chartTypes[type]);

      if (seriesAssigns.length) {
        // Use last options, value = index * dataFields
        const lastOption = seriesAssigns[seriesAssigns.length - 1];
        let options = {};
        Object.keys(lastOption).forEach((key) => {
          if (lastOption[key].isData) options[key] = lastOption[key];
        });
        const dataKeys = Object.keys(options);
        dataKeys.forEach((key) => {
          const dataOption = options[key];
          if (newOptions[key]) {
            newOptions[key].rawValue = [dataOption.rawValue[0] + dataKeys.length];
            newOptions[key].value = getLetterFromIndex(dataOption.rawValue[0] + dataKeys.length);
          }
        });
      }
      seriesAssigns.push(merge({}, newOptions));
      updatedProjectOptions.seriesAssigns = seriesAssigns;
    }

    // Init the initial chart
    if (skipEmit) return customizedOptions;

    yield put(setProjectConfigAction({ customizedOptions, ...updatedProjectOptions }));
    yield call(updateAggregated, true);
  } catch (error) {
    throw new Error(error);
  }
}

export function* setLocationMapTemplateData(customizedOptions, defaultOptions) {
  let seriesAssigns = [];
  yield put(
    setProjectConfigAction({
      seriesAssigns
    })
  );
  return {
    customizedOptions,
    defaultOptions
  };
}

export function* setHighchartsTemplateData(
  customizedOptions,
  chosenWizardTemplate,
  isNowTilemap,
  hcCode,
  defaultOptions
) {
  let csv = false;
  let isMap = customizedOptions?.chart?.map;

  const isNowPointMap = isPointMap(chosenWizardTemplate);
  const isNowPatternFill = isPatternFill(chosenWizardTemplate);

  if (chosenWizardTemplate.parseData) {
    // Needs some special parsing of data
    const templatePlugin = templates[chosenWizardTemplate.parseData].parseData;
    csv = toCSV(templatePlugin(parseCSV(defaultOptions.data), null, customizedOptions));
  }

  if (isMap && customizedOptions?.chart?.map !== defaultMap && !isNowTilemap && !isNowPointMap && !isNowPatternFill) {
    csv = getRandomMapData(Highcharts.maps[customizedOptions.chart.map].features, hcCode);
    customizedOptions.data.csv = csv;
  }

  customizedOptions.data = {
    csv: csv || defaultOptions.data
  };

  customizedOptions.data.seriesMapping = cloneDeep(defaultOptions.seriesMapping ?? []);
  customizedOptions.series = [];

  // let assignDataFields = defaultOptions.assignDataFields ?? [];
  let seriesAssigns = [];
  const [headers] = parseCSV(customizedOptions?.data?.csv);

  for (let i = 0; i < defaultOptions.series; i++) {
    customizedOptions = yield call(addBlankSeries, {
      data: {
        customizedOptions,
        index: i,
        skipEmit: true,
        extra: defaultOptions.seriesOptions ? defaultOptions.seriesOptions[i] : null,
        seriesAssigns,
        type: defaultOptions.seriesTemplate?.[i] ?? chosenWizardTemplate?.config?.chart?.type
      }
    });

    if (!defaultOptions.seriesMapping) {
      customizedOptions.data.seriesMapping.push({
        x: 0,
        y: i + 1
      });
    }

    let seriesMapping = customizedOptions.data.seriesMapping[i];
    headers.forEach((header, index) => (seriesMapping[parseDataNameForSeriesMapping(header)] = index));
  }

  if (defaultOptions.assignDataFields) {
    if (defaultOptions.assignDataFields.length !== seriesAssigns.length) {
      // Quick fix for packed bubble type
      seriesAssigns = [{ ...seriesAssigns[0] }];
    } else {
      seriesAssigns.forEach((series, seriesIndex) => {
        const assign = defaultOptions.assignDataFields[seriesIndex];
        Object.keys(assign).forEach((key) => {
          if (series[key]) {
            series[key].value = assign[key];
            series[key].rawValue = [getLetterIndex(assign[key])];
          }
        });
      });
    }
  }

  if (defaultOptions.dataTextValue) {
    yield put(
      setChartAction({
        dataTextValue: defaultOptions.dataTextValue
      })
    );
  }

  yield put(
    setProjectConfigAction({
      seriesAssigns
    })
  );

  return {
    customizedOptions,
    defaultOptions
  };
}

export function* setTemplateData(customizedOption, chosenWizardTemplate, isNowTilemap, hcCode) {
  if (!chosenWizardTemplate) {
    const chartConfig = yield select(getChartConfig);
    chosenWizardTemplate = chartConfig.chosenWizardTemplate;
  }

  let customizedOptions = cloneDeep(customizedOption);

  const type = chosenWizardTemplate.defaultDataType ?? chosenWizardTemplate.config?.chart?.type;
  const defaultOptions = DefaultData[type] ?? DefaultData.line;
  const provider = chosenWizardTemplate.provider ?? 'highcharts';

  switch (provider) {
    case 'highcharts':
      return yield call(
        setHighchartsTemplateData,
        customizedOptions,
        chosenWizardTemplate,
        isNowTilemap,
        hcCode,
        defaultOptions
      );

    case 'locationMap':
      return yield call(setLocationMapTemplateData, customizedOptions, defaultOptions);
  }
}

export function* clearData(params) {
  let { skipReinit } = params.data;

  const config = yield select(getProjectConfig);
  const customizedOptions = merge({}, config.customizedOptions);
  /*

  M.lastLoadedCSV = false;
  M.lastLoadedSheet = false;
  M.lastLoadedLiveData = false;
*/
  if (customizedOptions && customizedOptions.data) {
    customizedOptions.data = {};
  }

  if (customizedOptions.series) {
    customizedOptions.series = isArray(customizedOptions.series)
      ? customizedOptions.series
      : [customizedOptions.series];

    customizedOptions.series.forEach(function (series) {
      if (series.data) {
        delete series.data;
      }
    });
  }

  yield put(
    setProjectConfigAction({
      customizedOptions
    })
  );

  if (!skipReinit) {
    yield call(updateAggregated, true);
  }
}

export function* loadLiveData(params) {
  let { options, isDataServer, isMap } = params.data;

  const config = yield select(getProjectConfig);
  const customizedOptions = merge({}, config.customizedOptions);

  /*
  M.lastLoadedLiveData = settings;

  M.lastLoadedCSV = false;
  M.lastLoadedSheet = false;
  M.lastLoadedText = false;

  merge(customizedOptions, {
    data: M.lastLoadedLiveData
  });
  */

  merge(customizedOptions, {
    data: options
  });

  if (customizedOptions?.data?.csv) delete customizedOptions.data.csv;
  if (customizedOptions?.data?.seriesMapping) delete customizedOptions.data.seriesMapping;

  if (!isDataServer) {
    if (customizedOptions?.everviz?.data?.symbols) delete customizedOptions.everviz.data.symbols;
    if (customizedOptions?.everviz?.data?.interval) delete customizedOptions.everviz.data.interval;
    if (window?.everviz?.cachedDataServerData) {
      delete window.everviz.cachedDataServerData;
    }
  }

  if (customizedOptions && customizedOptions.series && customizedOptions.series.length > 0) {
    customizedOptions.series.forEach((serie) => {
      if ('_colorIndex' in serie) {
        delete serie._colorIndex;
      }
    });
  }

  yield put(
    setProjectConfigAction({
      customizedOptions,
      dataOptions: [[]]
    })
  );

  if (isMap && options?.csvURL) {
    yield call(loadMapLiveData, { data: { csvURL: options.csvURL } });
  } else {
    yield call(updateAggregated, true);
  }
}

export function* loadGoogleSheet(params) {
  let { options, isDataServer, isMap } = params.data;

  let key;
  const config = yield select(getProjectConfig);
  const customizedOptions = merge({}, config.customizedOptions);
  /*
  M.lastLoadedCSV = false;
  M.lastLoadedSheet = options;

  M.lastLoadedSheet.googleSpreadsheetKey =
    M.lastLoadedSheet.googleSpreadsheetKey || M.lastLoadedSheet.id;*/

  if (options && (options.googleSpreadsheetKey || '').indexOf('http') === 0) {
    // Parse out the spreadsheet ID
    // Located between /d/ and the next slash after that
    key = options.googleSpreadsheetKey;
    key = key.substr(key.indexOf('/d/') + 3);
    key = key.substr(0, key.indexOf('/'));

    options.googleSpreadsheetKey = key;
  }

  merge(customizedOptions, {
    data: options // M.lastLoadedSheet
  });
  /*
  if (highed.chartType === 'Map' && customizedOptions.series) {
    addBlankSeries(customizedOptions.series.length,null,{
      joinBy: null
    });
  }
*/

  if (customizedOptions?.data?.csv) delete customizedOptions.data.csv;
  if (customizedOptions?.data?.seriesMapping) delete customizedOptions.data.seriesMapping;

  if (!isDataServer) {
    if (customizedOptions?.everviz?.data?.symbols) delete customizedOptions.everviz.data.symbols;
    if (customizedOptions?.everviz?.data?.interval) delete customizedOptions.everviz.data.interval;
  }

  if (customizedOptions && customizedOptions.series && customizedOptions.series.length > 0) {
    customizedOptions.series.forEach((serie) => {
      if ('_colorIndex' in serie) {
        delete serie._colorIndex;
      }
    });
  }

  yield put(
    setProjectConfigAction({
      customizedOptions,
      dataOptions: [[]]
    })
  );

  if (isMap && options?.googleSpreadsheetKey) {
    yield call(loadMapGoogleSheetData, { data: { sheetOptions: options } });
  } else {
    yield call(updateAggregated, true);
  }
}

function* processMapLiveData(config, options, matchingKey, liveCsv = null) {
  const { seriesAssigns } = config;
  let customizedOptions = merge({}, config.customizedOptions);

  if (customizedOptions?.data?.itemDelimiter) delete customizedOptions.data.itemDelimiter;

  let { mapCodeIndex } = getMapCodeValueIndex(seriesAssigns);
  const mapData = Highcharts?.maps?.[customizedOptions?.chart?.map]?.features;
  const acceptedValues = getAcceptedValues(mapData, customizedOptions?.chart?.map);

  matchingKey = matchingKey ?? getMatchingKey(customizedOptions, options, mapData, acceptedValues);
  let newAssigns = cloneDeep(seriesAssigns);
  let customizedOptionChanges = {};

  if (matchingKey) {
    const [key, value] = getMapJoinByKeyValue(config, customizedOptions, matchingKey);
    const finalValue = isArray(value) ? value[0] : value;
    newAssigns = generateNewAssignsBasedOnMapCode(newAssigns, finalValue);
    set(customizedOptions, key, value);
  }

  let hcCode = getMapKey(customizedOptions);
  options = parseCodesForCaseSensitivity(customizedOptions, options, mapCodeIndex, hcCode, mapData);

  const { mapExtraColumns, mapCodeErrors } = getMapCodeErrorFields(
    customizedOptions,
    options,
    mapCodeIndex,
    hcCode,
    mapData
  );

  ({ seriesAssigns: newAssigns, customizedOptionChanges } = parseDataForValueColumn(
    customizedOptions,
    newAssigns,
    options,
    mapCodeIndex
  ));

  merge(customizedOptions, customizedOptionChanges);

  const [headers] = options;
  mapExtraColumns.forEach((code) => {
    const row = new Array(headers.length).fill(null);
    row[mapCodeIndex] = code;
    options.push(row);
  });

  yield put(
    setProjectConfigAction({
      customizedOptions,
      dataOptions: options,
      rawImportedCSV: liveCsv,
      mapCodeErrors,
      mapExtraColumns
    })
  );

  yield put(updateSeriesMappingAction({ newAssigns, isMap: true }));
  yield call(updateAggregated, true);
}

export function* loadMapGoogleSheetData(params) {
  let { sheetOptions, matchingKey } = params.data;
  let options = null;

  try {
    const response = yield call(
      fetchGoogleSheetData,
      sheetOptions?.googleSpreadsheetKey,
      sheetOptions?.googleSpreadsheetRange
    );
    if (response?.values) options = response?.values;
  } catch (error) {
    console.error('Error fetching Google Sheet data:', error);
  }

  const config = yield select(getProjectConfig);
  yield* processMapLiveData(config, options, matchingKey);
}

function* handleRawMapCSVImport(customizedOptions, options, shouldConvertCSV) {
  const dataOptions = cloneDeep(options);
  if (shouldConvertCSV) options = toCSV(options);

  customizedOptions.data = merge(customizedOptions.data, {
    ...customizedOptions.data,
    csv: options
  });

  yield put(
    setProjectConfigAction({
      customizedOptions,
      dataOptions
    })
  );

  yield call(updateAggregated, true);
}

export function* loadMapLiveData(params) {
  let { csvURL, liveCsv, matchingKey } = params.data;
  let options = null;

  if (!liveCsv && csvURL) liveCsv = yield call(fetchCsv, csvURL);

  const parsed = parseCSV(liveCsv, null, null);
  options = parsed.filter((a) => a.length !== 0);

  const config = yield select(getProjectConfig);
  yield* processMapLiveData(config, options, matchingKey, liveCsv);
}

export function* loadMapCSV(params) {
  let { options, skipMapping, matchingKey } = params.data;

  try {
    const columnTypes = columnDataType(options);
    const config = yield select(getProjectConfig);
    const { seriesAssigns } = config;
    let customizedOptions = merge({}, config.customizedOptions);
    if (customizedOptions?.data?.csvURL) delete customizedOptions.data.csvURL;

    const isTilemap = customizedOptions?.chart?.type === 'tilemap';
    const isPointMap = isPointMapFromConfig(customizedOptions);
    let { mapCodeIndex } = getMapCodeValueIndex(seriesAssigns);
    const mapData = isTilemap ? null : Highcharts?.maps?.[customizedOptions?.chart?.map]?.features;
    const acceptedValues = getAcceptedValues(
      Highcharts?.maps?.[customizedOptions?.chart?.map]?.features,
      customizedOptions?.chart?.map
    );
    matchingKey = matchingKey ?? getMatchingKey(customizedOptions, options, mapData, acceptedValues);
    let newAssigns = cloneDeep(seriesAssigns);
    let customizedOptionChanges = {};

    if (skipMapping || isTilemap || isPointMap) {
      // Used for point maps and tilemap
      yield call(handleRawMapCSVImport, customizedOptions, options, isTilemap || isPointMap);
      return;
    }

    if (matchingKey) {
      const [key, value] = getMapJoinByKeyValue(config, customizedOptions, matchingKey);
      const finalValue = isArray(value) ? value[0] : value;
      newAssigns = generateNewAssignsBasedOnMapCode(newAssigns, finalValue);
      set(customizedOptions, key, value);
    }

    let hcCode = getMapKey(customizedOptions);
    options = parseCodesForCaseSensitivity(customizedOptions, options, mapCodeIndex, hcCode, mapData);

    const { mapExtraColumns, mapCodeErrors } = getMapCodeErrorFields(
      customizedOptions,
      options,
      mapCodeIndex,
      hcCode,
      mapData
    );

    ({ seriesAssigns: newAssigns, customizedOptionChanges } = parseDataForValueColumn(
      customizedOptions,
      newAssigns,
      options,
      mapCodeIndex
    ));

    merge(customizedOptions, customizedOptionChanges);

    // Fill columns user hasnt passed in to the bottom of the
    // datagrid so they can add these in afterwards if they want.
    const [headers] = options;
    mapExtraColumns.forEach((code) => {
      const row = new Array(headers.length).fill(null);
      row[mapCodeIndex] = code;
      options.push(row);
    });

    // This could potentially get changed in "parseDataForValueColumn" so we call this
    // afterwards rather than retrieve it at the top of the function with the code
    let { mapValueIndex } = getMapCodeValueIndex(newAssigns);

    let dataCSV;
    if (length > 1) dataCSV = toMapCSV(options, mapValueIndex, ';');
    else dataCSV = options;

    customizedOptions.data = merge(customizedOptions.data, {
      ...customizedOptions.data,
      csv: dataCSV,
      itemDelimiter: ';'
    });

    if (columnTypes) customizedOptions.data['columnTypes'] = columnTypes;

    yield put(
      setProjectConfigAction({
        customizedOptions,
        dataOptions: options,
        mapCodeErrors,
        mapExtraColumns
      })
    );
    yield put(updateSeriesMappingAction({ newAssigns, isMap: true }));
    yield call(updateAggregated, true);
  } catch (e) {
    console.log(e);
  }
}

export function* updateMapErrorFields() {
  const { customizedOptions, dataOptions, seriesAssigns } = yield select(getProjectConfig);
  let { mapCodeIndex } = getMapCodeValueIndex(seriesAssigns);
  let hcCode = getMapKey(customizedOptions);
  const { mapCodeErrors } = getMapCodeErrorFields(customizedOptions, dataOptions, mapCodeIndex, hcCode);

  yield put(
    setProjectConfigAction({
      mapExtraColumns: [],
      mapCodeErrors
    })
  );
}

export function* loadChartCSV(params) {
  let { options, itemDelimiter } = params;
  const config = yield select(getProjectConfig);
  let customizedOptions = merge({}, config.customizedOptions);
  const aggregatedOptions = merge({}, config.aggregatedOptions) ?? {};
  let assignOptions = [];
  let parseAssigns = false;
  let rawOptions = cloneDeep(options);
  let templateParseData = config?.templateMeta[0]?.templateParseData;

  if (templateParseData) {
    const parseData = templates[templateParseData].parseData;
    parseAssigns = templates[templateParseData].parseAssigns;
    options = parseData(options, true, customizedOptions);
  }
  try {
    const type = getChartSeriesType(customizedOptions, 0, aggregatedOptions);
    const parsedDataDetails = yield call(parseDataForValidColumns, type, options, itemDelimiter);
    const newSeriesAssigns = parsedDataDetails.seriesAssigns;
    const newAssignsLength = newSeriesAssigns.length;
    const seriesAssigns = customizedOptions.series ?? [];
    if (parseAssigns) {
      // Template is a special case and doesnt fall into normal series mapping workflow
      // Use custom processing for this. This is set up on the template type.
      const seriesMapping = parseAssigns(options, newSeriesAssigns, null, customizedOptions);
      customizedOptions.data = {
        csv: toCSV(options),
        seriesMapping
      };
    } else {
      if (newAssignsLength) {
        if (seriesAssigns.length > newAssignsLength) {
          seriesAssigns.splice(newAssignsLength, seriesAssigns.length - newAssignsLength);
        } else if (seriesAssigns.length < newAssignsLength) {
          const diff = newAssignsLength - seriesAssigns.length;
          for (let i = 1; i <= diff; i++) {
            customizedOptions = yield call(addBlankSeries, {
              data: {
                index: seriesAssigns.length,
                skipEmit: true,
                customizedOptions
              }
            });
          }
        }
      }

      newSeriesAssigns.forEach((series, i) => {
        if (!assignOptions[i]) assignOptions[i] = {};
        Object.keys(series).forEach((key) => {
          assignOptions[i][key] = series[key].value;
        });
      });

      const section = getAllMergedLabelAndData(assignOptions);
      let dataFieldsUsed = [];

      parsedDataDetails.rawData[0].forEach((col, index) => {
        if (section && !checkSections(section, index)) return;
        if (dataFieldsUsed.indexOf(index) === -1) {
          dataFieldsUsed.push(index);
        }
      });

      customizedOptions = merge(customizedOptions, parsedDataDetails.customizedOptionChanges);
      const state = {
        customizedOptions,
        seriesAssigns: newSeriesAssigns,
        aggregatedOptions,
        dataOptions: templateParseData ? rawOptions : parsedDataDetails.rawData ?? rawOptions,
        dataGridColumnsUsed: dataFieldsUsed,
        templateMeta: config.templateMeta
      };

      const isMap = false;
      yield call(saveDataAndConfigAsCSV, state, isMap, itemDelimiter);
      yield call(setSeriesMapping, state);

      customizedOptions.data = {
        csv: state.customizedOptions.data.csv,
        seriesMapping: state.customizedOptions.data.seriesMapping,
        itemDelimiter,
        decimalPoint: parsedDataDetails.decimalDelimiter
      };
    }

    const columnTypes = columnDataType(parsedDataDetails.rawData ?? rawOptions);
    customizedOptions.data.columnTypes = [...columnTypes];
    const dateFormat = getDateFormat(rawOptions);
    if (dateFormat) customizedOptions.data['dateFormat'] = dateFormat;

    yield put(
      setProjectConfigAction({
        customizedOptions,
        dataOptions: templateParseData ? rawOptions : parsedDataDetails.rawData ?? rawOptions,
        seriesAssigns: newSeriesAssigns
      })
    );

    yield call(updateAggregated, true);
  } catch (error) {
    console.error(error);
  }
}

const loadMapDataMap = {
  highcharts: loadMapCSV
};

export function* loadCSV(params) {
  let { isMap, itemDelimiter, csv, options, matchingKey } = params.data;
  const { provider } = yield select((state) => state.projectConfig);
  if (!options) {
    const rows = (csv || '').replace(/\r\n/g, '\n').split('\n');
    if (!itemDelimiter) itemDelimiter = guessDelimiter(rows);
    const parsed = parseCSV(csv, itemDelimiter, null);
    options = parsed.filter((a) => a.length !== 0);
  }
  if (isMap && provider !== 'locationMap') {
    yield call(loadMapDataMap[provider], { data: { options, matchingKey, itemDelimiter } });
  } else {
    if (isMap) yield call(loadMapCSV, { data: { options, matchingKey } });
    else yield call(loadChartCSV, { options, itemDelimiter });
  }
}

// Word cloud
export function* loadTextData() {
  const config = yield select(getProjectConfig);
  const chartConfig = yield select(getChartConfig);
  const customizedOptions = merge({}, config.customizedOptions);
  let { dataTextValue } = chartConfig;

  if (!isObject(dataTextValue)) {
    dataTextValue = {
      text: dataTextValue || ''
    };
  }

  const csv = getCSVFromText(dataTextValue.text, dataTextValue.amount);
  const dataOptions = parseCSV(csv);

  customizedOptions.data = {
    text: dataTextValue,
    seriesMapping: customizedOptions.data?.seriesMapping,
    csv
  };
  yield put(
    setProjectConfigAction({
      customizedOptions,
      dataOptions
    })
  );

  yield call(updateAggregated, true);
}

export function* setData(params) {
  const { options, dataType, cb, skipNotification, csv, isMap } = params.data;
  const projectConfig = yield select(getProjectConfig);

  if (!skipNotification) {
    yield put(
      setAction({
        uploadingData: true
      })
    );
  }

  if (csv && !projectConfig.rawImportedCSV) {
    yield put(setProjectConfigAction({ rawImportedCSV: csv }));
  }

  if (dataType === 'csv') yield call(loadCSV, { data: { isMap, options, csv } });
  else if (dataType === 'text') yield call(loadTextData);
  else {
    const isGSheet = dataType === 'google';
    const typedFunction = isGSheet ? loadGoogleSheetAction : loadLiveDataAction;
    yield put(
      typedFunction({
        options,
        isMap,
        isDataServer: dataType === 'data-server'
      })
    );
  }

  if (!skipNotification) {
    yield delay(1000);
    yield put(
      setAction({
        uploadingData: false
      })
    );
  }

  yield put(
    setAction({
      uploadingData: false
    })
  );

  if (cb) cb();
}

export function* adjustSeriesAssigns(params) {
  const { seriesAssigns, hcCode, isMap } = params.data;
  const projectConfigOptions = {};

  try {
    if (seriesAssigns) {
      const { aggregatedOptions } = yield select(getProjectConfig);
      const series = getSeriesType(aggregatedOptions);

      projectConfigOptions.seriesAssigns = series.map((type, index) => {
        let options = cloneDeep(assignDefaults);
        options = clean(merge(options, chartTypes[type]));
        const mappedValues = seriesAssigns[index];
        Object.keys(mappedValues ?? []).forEach((key) => {
          if (options[key]) {
            options[key].value = isObject(mappedValues[key]) ? mappedValues[key].value : mappedValues[key];
            options[key].rawValue = isObject(mappedValues[key])
              ? mappedValues[key].rawValue
              : [getLetterIndex(mappedValues[key])];
          }
        });
        return options;
      });

      if (isMap) {
        projectConfigOptions.seriesAssigns = (projectConfigOptions.seriesAssigns ?? []).map((assign) => {
          if (assign?.labels?.linkedTo === 'hc-key' && hcCode) {
            assign.labels.linkedTo = hcCode;
          }
          return assign;
        });
      }
    }

    yield put(setProjectConfigAction(projectConfigOptions));
  } catch (error) {
    console.error(error);
  }
}

/** Watch functions */
export function* watchAddBlankSeries() {
  yield takeEvery(actionTypes.chartEditor.addBlankSeries, addBlankSeries);
}
export function* watchDeleteSeries() {
  yield takeEvery(actionTypes.chartEditor.deleteSeries, deleteSeries);
}
export function* watchDeleteSingleSeries() {
  yield takeEvery(actionTypes.chartEditor.deleteSingleSeries, deleteSingleSeries);
}
export function* watchClearData() {
  yield takeEvery(actionTypes.chartEditor.clearData, clearData);
}
export function* watchLoadGoogleSheet() {
  yield takeEvery(actionTypes.chartEditor.loadGoogleSheet, loadGoogleSheet);
}
export function* watchLoadLiveData() {
  yield takeEvery(actionTypes.chartEditor.loadLiveData, loadLiveData);
}
export function* watchSetData() {
  yield takeEvery(actionTypes.chartEditor.setData, setData);
}
export function* watchLoadCSV() {
  yield takeEvery(actionTypes.chartEditor.loadCSV, loadCSV);
}
export function* watchLoadMapCSV() {
  yield takeEvery(actionTypes.chartEditor.loadMapCSV, loadMapCSV);
}

export function* watchLoadMapLiveData() {
  yield takeEvery(actionTypes.chartEditor.loadMapLiveData, loadMapLiveData);
}

export function* watchLoadMapGoogleSheetData() {
  yield takeEvery(actionTypes.chartEditor.loadMapGoogleSheetData, loadMapGoogleSheetData);
}

export function* watchAdjustSeriesAssigns() {
  yield takeEvery(actionTypes.chartEditor.adjustSeriesAssigns, adjustSeriesAssigns);
}

export default function* rootSaga() {
  yield all([
    watchAddBlankSeries(),
    watchDeleteSeries(),
    watchDeleteSingleSeries(),
    watchClearData(),
    watchLoadGoogleSheet(),
    watchLoadLiveData(),
    watchSetData(),
    watchLoadLiveData(),
    watchLoadMapGoogleSheetData(),
    watchLoadMapCSV(),
    watchLoadCSV(),
    watchLoadMapLiveData(),
    watchAdjustSeriesAssigns()
  ]);
}

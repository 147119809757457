import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { SectionNode } from '../../SectionNode';
import { MarkerSectionHeaderText } from '../../locationMap/MarkerSection';
import TextWidget from 'shared/widgets/TextWidget';
import { setAction as setProjectConfigAction } from '../../../../../redux/actions/projectConfig';
import { MarkerConfig } from '@visual-elements/location-map';
import AccordionButton from 'shared/widgets/AccordionButton';
import BinIcon from 'static/icons/bin.svg';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import { cloneDeep } from 'lodash';
import { updateLocationMapCustomizedAction } from 'pages/ChartEditorPage/actions/locationMap';

const EBRDMarkerSection = (props: { marker: MarkerConfig; index: number }) => {
  const { marker, index } = props;
  const dispatch = useDispatch();
  const { aggregatedOptions } = useSelector((state: RootState) => state.projectConfig);

  const changeSaveStatus = () => {
    dispatch(
      setProjectConfigAction({
        saved: false,
        changeMade: true
      })
    );
  };

  const handleMarkersTitleChange = (e: any) => {
    dispatch(
      updateLocationMapCustomizedAction({
        optionProps: `markers[${index}].label.text`,
        val: e.val
      })
    );

    changeSaveStatus();
  };

  const handleMarkersDetailsChange = (e: any) => {
    dispatch(
      updateLocationMapCustomizedAction({
        optionProps: `markers[${index}].tooltip.text`,
        val: e.val
      })
    );

    changeSaveStatus();
  };

  const deleteMarker = (marker: MarkerConfig) => {
    const clonedMetaData = cloneDeep(aggregatedOptions);
    clonedMetaData.markers = clonedMetaData.markers.filter((item: MarkerConfig) => item.id !== marker.id);

    dispatch(
      updateLocationMapCustomizedAction({
        optionProps: 'markers',
        val: clonedMetaData.markers
      })
    );

    changeSaveStatus();
  };

  return (
    <SectionNode
      sectionKey={`marker_section_${marker.id}`}
      parentSectionKey={'markers'}
      showContainer={true}
      smallHeaderText={true}
      headerText={<MarkerSectionHeaderText labelText={marker.label.text} iconEnabled={marker.icon.enabled} />}
      accordionClasses={'bg-white'}
      openedByDefault={false}
    >
      <TextWidget className="bg-ev-grey" onChange={handleMarkersTitleChange} value={marker.label.text} />

      <TextWidget
        className="bg-ev-grey"
        onChange={handleMarkersDetailsChange}
        //@ts-expect-error tooltip not added to location map yet.
        value={marker?.tooltip?.text ?? ''}
      />

      <AccordionButton
        handleClick={() => deleteMarker(marker)}
        label="Delete this marker"
        size={16}
        buttonIcon={BinIcon}
        buttonColor={ButtonColor.White}
        iconClasses="p-2 rounded-full mx-2"
      />
    </SectionNode>
  );
};

export default EBRDMarkerSection;

import { cloneDeep } from 'lodash';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import actionTypes from 'redux/actions/action-types';
import { getProjectConfig } from 'redux/selectors/projectConfig';
import { setAction as setProjectConfigAction } from '../../../../redux/actions/projectConfig';
import { updateAggregated } from '../ChartEditor';
import { GenericPayload } from '../ChartEditorPointMap';
import { MapResolution, MapViewState } from '@visual-elements/location-map';
import { ProjectConfigLocationMapProps } from '../../../Editor/reducers/locationMapConfigTypes';

type UpdateReferencePointResolutionPayload = GenericPayload & {
  data: MapResolution;
};

export function* updateReferencePointResolution(params: UpdateReferencePointResolutionPayload) {
  try {
    const { customizedOptions }: ProjectConfigLocationMapProps = yield select(getProjectConfig);
    const newCustomizedOptions = cloneDeep(customizedOptions);

    newCustomizedOptions.viewState = {
      ...newCustomizedOptions.viewState,
      referenceHeight: params.data.height,
      referenceWidth: params.data.width
    };

    yield put(
      setProjectConfigAction({
        customizedOptions: newCustomizedOptions
      })
    );
    yield call(updateAggregated);
  } catch (err) {
    console.log(err);
  }
}

type UpdateMapViewStatePayload = GenericPayload & {
  data: { viewState: MapViewState; resolution: MapResolution };
};

export function* updateViewState(params: UpdateMapViewStatePayload) {
  try {
    const { locationMapOptions, customizedOptions }: ProjectConfigLocationMapProps = yield select(getProjectConfig);
    const newLocationMapOptions = cloneDeep(locationMapOptions);
    const newCustomizedOptions = cloneDeep(customizedOptions);

    newCustomizedOptions.viewState = {
      ...newCustomizedOptions.viewState,
      bearing: params.data.viewState.bearing,
      center: params.data.viewState.center,
      pitch: params.data.viewState.pitch,
      referenceHeight: params.data.resolution.height,
      referenceWidth: params.data.resolution.width,
      zoom: params.data.viewState.referenceZoom
    };

    yield put(
      setProjectConfigAction({
        locationMapOptions: newLocationMapOptions,
        customizedOptions: newCustomizedOptions
      })
    );
    yield call(updateAggregated);
  } catch (err) {
    console.log(err);
  }
}

export function* watchUpdateReferencePointResolution() {
  yield takeEvery(actionTypes.locationMap.updateReferencePointResolution, updateReferencePointResolution);
}

export function* watchUpdateViewState() {
  yield takeEvery(actionTypes.locationMap.updateViewState, updateViewState);
}

export default function* rootSaga() {
  yield all([watchUpdateViewState(), watchUpdateReferencePointResolution()]);
}

import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { SectionNode } from '../../SectionNode';
import { MarkerConfig } from '@visual-elements/location-map';
import EBRDMarkerSection from './ebrdMarkerSection';

const EBRDMarkersSection = () => {
  const { aggregatedOptions } = useSelector((state: RootState) => state.projectConfig);
  const markers = aggregatedOptions?.markers ?? [];

  return (
    <SectionNode showContainer={true} sectionKey={'markers'} headerText="Markers" openedByDefault={false}>
      <div className="flex flex-col gap-2">
        {markers && markers.length > 0 ? (
          markers.map((marker: MarkerConfig, index: number) => {
            return <EBRDMarkerSection key={`marker_${index}`} marker={marker} index={index} />;
          })
        ) : (
          <div className="text-sm">No markers have been added yet. Begin adding markers to customize your map.</div>
        )}
      </div>
    </SectionNode>
  );
};

export default EBRDMarkersSection;

import { LocationMapContainerRef } from '@visual-elements/location-map';
import { config } from '@visual-elements/packages';
import EditorError from 'pages/ChartEditorPage/components/EditorError';
import EditorPublish from 'pages/ChartEditorPage/components/EditorPublish';
import PanelOptions from 'pages/PackageEditorPage/meta/PanelOptions';
import { loadPackageProjectAction } from 'pages/PackagesPage/actions/packagesPage';
import React, { createRef, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { setAction as setProjectConfigAction } from 'redux/actions/projectConfig';
import { selectIsPreviewMap, storeLocationMap } from 'redux/reducers/locationMap/instanceReducer';
import { RootState } from 'redux/store';
import { ViewBox } from 'shared/editor/generic/locationMap/ViewBox';
import OptionsPanel from 'shared/editor/generic/OptionsPanel';
import OptionsPanelContainer from 'shared/editor/generic/OptionsPanelContainer';
import ProjectContainer from 'shared/editor/generic/ProjectContainer';
import WorkspaceBar from 'shared/editor/generic/WorkspaceBar';
import { resetReduxState } from 'shared/utils/editorHelper';
import { useIsMobile } from '../../shared/utils/mediaHelper';

type PackageEditorProps = {
  showWizard: boolean;
  aggregatedOptions: any;
  history: RouteComponentProps['history'];
  chart: any;
  isMap: boolean;
};

interface RouteParams {
  projectid: string;
  packageuuid: string;
}

const dimensionsOptions = {
  type: 'auto'
};

const LocationMapPackageEditor = (props: PackageEditorProps) => {
  const dispatch = useDispatch();
  const containerRef = createRef<HTMLDivElement>();
  const { themeData, stockSymbol } = useSelector((state: RootState) => state.packagesPage);
  const { aggregatedOptions, loadingEditor } = useSelector((state: RootState) => state.projectConfig);
  const { team } = useSelector((state: RootState) => state.profile);
  const { projectid }: RouteParams = useParams();
  const isPreview = useSelector(selectIsPreviewMap);

  const isMobile = useIsMobile();
  const myPanelOptions = PanelOptions;

  useEffect(() => {
    dispatch(loadPackageProjectAction({ uuid: projectid }));

    dispatch(
      setProjectConfigAction({
        inPackagesMode: true,
        tab: 'customize',
        provider: 'locationMap',
        loadingEditor: false,
        aggregatedOptions: {
          disableLoading: true,
          markers: [],
          viewState: {},
          viewIsLocked: false
        },
        customizedOptions: {
          disableLoading: true,
          markers: [],
          viewState: {},
          viewIsLocked: false
        }
      })
    );

    return () => {
      resetReduxState(dispatch);
    };
  }, []);

  const initLocationMapInEditor = useCallback((ref: LocationMapContainerRef | null) => {
    if (ref) {
      dispatch(storeLocationMap(ref));
    }
  }, []);

  const Component = useMemo(() => {
    return config[4].projects[0].component;
  }, []);

  return (
    <div className="story chart-type flex flex-col h-full">
      {isMobile && <EditorPublish />}
      <WorkspaceBar panelOptions={myPanelOptions} />

      <div className="flex w-full h-full flex-col ev-sm:flex-row">
        <OptionsPanelContainer>
          <OptionsPanel options={{ ...props.aggregatedOptions }} panelOptions={myPanelOptions} />
        </OptionsPanelContainer>
        <ProjectContainer>
          <div className="w-full h-full flex justify-center">
            <div ref={containerRef} className="canvas-container bg-transparent border-0 w-full h-full flex">
              {loadingEditor && (
                <>
                  <Component
                    teamId={team.id}
                    theme={themeData}
                    dimensions={{ dimensionsOptions }}
                    useDynamicResizing={true}
                    animations={true}
                    stockSymbol={stockSymbol}
                    metadata={aggregatedOptions}
                    initLocationMapInEditor={initLocationMapInEditor}
                  />

                  {!isPreview && <ViewBox />}
                </>
              )}
            </div>
          </div>
        </ProjectContainer>
      </div>

      <EditorError />
    </div>
  );
};

export default LocationMapPackageEditor;

import { SubscriptionPlans } from 'pages/SubscriptionPage/types/plansModels';
import { getPlanById } from 'pages/SubscriptionPage/utils/plansHelper';
import { getAggregatedOptions } from 'pages/TableEditorPage/selectors/tableEditor';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRecentExportAction } from 'redux/actions/profile';
import { RootState } from 'redux/store';
import Accordion from 'shared/Accordion';
import PrimaryButton from 'shared/buttons/PrimaryButton';
import SvgIconButton from 'shared/buttons/SvgIconButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import { Dimensions } from 'shared/editor/generic/ResolutionSection';
import { downloadChartImage, downloadLocationMapImage, downloadTableImage } from 'shared/editor/publishHelper';
import CustomSelect, { SelectItem } from 'shared/inputs/CustomSelect';
import { editorStyles } from 'shared/utils/selectStylesHelper';
import CheckboxWidget from 'shared/widgets/CheckboxWidget';
import { ImageMimeType, ImageOption, ImageOptionType, imageOptions } from 'shared/wizard/meta/ImageOptions';
import { Orientation, getFormatList, socialMediaOptions } from 'shared/wizard/meta/SocialMediaOptions';
import { VideoMimeType, VideoOptionsType, VideoTypes, gifVideoOption } from 'shared/wizard/meta/VideoOptions';
import {
  getLocationMapConfigForImageExport,
  getLocationMapConfigForVideoExport
} from 'shared/wizard/utils/locationMapMapper';
import { downloadVideo } from 'shared/wizard/utils/videoHelper';
import HorizontalOrientationIcon from 'static/icons/horizontal.svg';
import SquareOrientationIcon from 'static/icons/square.svg';
import VerticalOrientationIcon from 'static/icons/vertical.svg';
import ExportDimensions from './ExportDimensions';
import GeneratingVideo from './GeneratingVideo';
import withExportDimensions, { ExportDimensionsProps } from './withExportDimensions';

interface SocialSelectItem extends Omit<SelectItem, 'value'> {
  value: VideoMimeType | ImageMimeType;
  type: 'video' | 'image';
}

const SocialChannel = (props: ExportDimensionsProps) => {
  const dispatch = useDispatch();
  const { setResolution, dimensions, showPaidPlanModal, showSubscriptionLock } = props;
  const [imageOption, setImageOption] = useState<(ImageOptionType | VideoOptionsType)[]>(imageOptions);
  const socialMediaItems = Object.values(socialMediaOptions).map((opt) => opt.item);
  const [socialType, setSocialType] = useState<SelectItem>(socialMediaItems[0]);
  const [imageFormat, setImageFormat] = useState<SocialSelectItem>(imageOption[0].item);
  const [orientation, setOrientation] = useState(Orientation.Portrait);
  const [loop, setLoop] = useState(false);
  const [callRecentExport, shouldCallRecentExport] = useState(false);
  const [videoStatus, setVideoStatus] = useState({
    generating: false,
    done: false
  });

  const { constr, chartId } = useSelector((state: RootState) => state.chartEditorPage);
  const { storyId } = useSelector((state: RootState) => state.layoutEditorPage);
  const projectConfig = useSelector((state: RootState) => state.projectConfig);
  const { dataOptions, cssModules, uuid, type, provider, aggregatedOptions, projectName, inPackagesMode } =
    projectConfig;

  const { user, team } = useSelector((state: RootState) => state.profile);
  const planName = getPlanById(team?.plan_id ?? 1);
  const onStarter = planName === SubscriptionPlans.Starter;
  const tableAggregatedOptions = useSelector((state: RootState) => getAggregatedOptions(state.projectConfig));
  const { locationMap } = useSelector((state: RootState) => state.locationMapInstance);
  const lockedButton = (onStarter && imageFormat.type === 'video') || showSubscriptionLock;
  useEffect(() => {
    generateFormatList();
  }, [type]);

  const handleDimensionsChange = () => {
    const newDimension = Object.entries(socialMediaOptions).reduce((filtered, [key, value]) => {
      if (key === socialType.label) filtered = value.defaultDimensions[orientation];
      return filtered;
    }, {} as Dimensions);
    setResolution(newDimension);
  };

  const setDefaultVideoFormat = () => {
    const newImageFormat = Object.entries(socialMediaOptions).reduce((filtered, [key, value]) => {
      if (key === socialType.label) filtered = value.defaultImageFormat.item;
      return filtered;
    }, {} as SocialSelectItem);
    setImageFormat(newImageFormat);
  };

  const generateFormatList = () => {
    if (type === 'table' || type === 'layout') {
      const imgOption = imageOption.filter((opt) => opt.id === ImageOption.PNG);
      setImageOption(imgOption);
      setImageFormat(imgOption[0].item);
    } else setImageOption(getFormatList(socialType.value));
  };

  useEffect(() => {
    setDefaultVideoFormat();
    generateFormatList();
  }, [socialType]);

  useEffect(() => {
    handleDimensionsChange();
  }, [orientation]);

  useEffect(() => {
    dispatch(getRecentExportAction());
    shouldCallRecentExport(false);
  }, [callRecentExport]);

  const setGeneratingVideo = (status: boolean) => {
    setVideoStatus({
      generating: status,
      done: !status
    });

    if (!status) {
      setTimeout(() => {
        setVideoStatus({
          generating: false,
          done: false
        });
      }, 3000);
    }
  };

  const download = () => {
    const isVideoFormat = VideoTypes.includes(imageFormat.value as VideoMimeType);
    if (isVideoFormat) {
      let options = aggregatedOptions;

      //@ts-expect-error quickfix
      if (inPackagesMode && provider === 'locationMap') options = locationMap.options;
      else if (provider === 'locationMap') {
        options = getLocationMapConfigForVideoExport(aggregatedOptions, dimensions);
      }

      downloadVideo({
        constr,
        setGeneratingVideo,
        aggregatedOptions: options,
        type: imageFormat.value,
        exportedBy: user.id,
        uuid,
        user,
        loop: loop ? '0' : '-1',
        teamId: team?.id,
        chartId: chartId,
        projectName,
        provider,
        shouldCallRecentExport
      });
    } else downloadImage();
  };

  const downloadImage = () => {
    const teamId = team?.id;
    if (type === 'chart') {
      switch (provider) {
        case 'highcharts':
          return downloadChartImage({
            constr,
            aggregatedOptions,
            type: imageFormat.value,
            exportedBy: user.id,
            uuid,
            teamId: team?.id,
            chartId,
            projectName,
            shouldCallRecentExport
          });
        case 'locationMap': {
          const options = inPackagesMode
            ? //@ts-expect-error quick fix
              locationMap.options
            : getLocationMapConfigForImageExport(aggregatedOptions, dimensions);

          return downloadLocationMapImage({
            type: imageFormat.value,
            options,
            uuid,
            cssModules,
            teamId,
            chartId,
            projectName,
            exportedBy: user.id,
            shouldCallRecentExport
          });
        }
      }
    } else {
      downloadTableImage({
        aggregatedOptions: tableAggregatedOptions,
        type,
        exportedBy: user.id,
        cssModules,
        dataOptions,
        uuid,
        teamId,
        tableId: storyId,
        projectName,
        shouldCallRecentExport
      });
    }
  };

  return (
    <div className="flex flex-col">
      <p className="text-sm mb-4 text-black">Select your preferred channel and format to export your project as.</p>
      <CustomSelect
        label="Channel"
        options={Object.values(socialMediaOptions).map((opt) => opt.item)}
        onChange={(selected) => setSocialType(selected as SelectItem)}
        styles={editorStyles}
        value={socialType}
      />
      <CustomSelect
        label="Format"
        options={Object.values(imageOption).map((opt) => opt.item)}
        onChange={(selected) => setImageFormat(selected as SocialSelectItem)}
        styles={editorStyles}
        value={imageFormat}
        containerClasses="mt-2"
      />
      <Accordion
        headerText="Post dimensions"
        useInternalSelect={true}
        defaultSelection={false}
        accordionClasses="w-full bg-white mt-2"
        smallHeaderText={true}
        selectedChildren={orientation}
      >
        <div className="flex gap-1">
          <SvgIconButton
            Icon={VerticalOrientationIcon}
            buttonColor={orientation === Orientation.Portrait ? ButtonColor.NavyBlue : ButtonColor.Transparent}
            buttonClasses="flex content-centered rounded w-10 h-10"
            width={20}
            height={32}
            onClick={() => setOrientation(Orientation.Portrait)}
          />
          <SvgIconButton
            Icon={HorizontalOrientationIcon}
            buttonColor={orientation === Orientation.Landscape ? ButtonColor.NavyBlue : ButtonColor.Transparent}
            buttonClasses="flex content-centered rounded w-10 h-10"
            width={32}
            height={20}
            onClick={() => setOrientation(Orientation.Landscape)}
          />
          <SvgIconButton
            Icon={SquareOrientationIcon}
            buttonColor={orientation === Orientation.Square ? ButtonColor.NavyBlue : ButtonColor.Transparent}
            buttonClasses="flex content-centered rounded w-10 h-10"
            width={20}
            height={20}
            onClick={() => setOrientation(Orientation.Square)}
          />
        </div>
      </Accordion>
      <ExportDimensions dimensions={dimensions} setResolution={setResolution} />
      {imageFormat.value === gifVideoOption.item.value && (
        <CheckboxWidget label="Loop" value={loop} onChange={({ val }) => setLoop(val as boolean)} />
      )}
      <PrimaryButton
        useLoader={{
          default: {
            text: 'Export'
          },
          loading: {
            text: 'Export',
            check: videoStatus.generating
          },
          done: {
            text: 'Export',
            icon: 'check-circle',
            check: videoStatus.done
          }
        }}
        onClick={lockedButton ? showPaidPlanModal : download}
        className="content-centered mt-4 px-6 h-10 text-base self-end"
        showSubscriptionLock={lockedButton}
      />
      {videoStatus.generating && <GeneratingVideo userEmail={user.email} />}
    </div>
  );
};

export default withExportDimensions(SocialChannel);

import { LocationMapCustomConfig, LocationMapResolution, MapViewState } from '@visual-elements/location-map';
import { LocationMapAggregatedOptions } from '../../../pages/Editor/reducers/locationMapConfigTypes';
import { CSSProperties } from 'react';

type Override<T> = { override: true; value: T } | { override: false };
type Append<T> = { append: true; value: T } | { append: false };

export type LocationMapMappingOptions = {
  viewState?: Override<MapViewState>;
  resolution?: Override<LocationMapResolution>;
  disableText?: boolean;
  disableInteractiveControls?: boolean;
  disableAttribution?: boolean;

  enableFeatureEditing?: boolean;
  disableInitialAnimation?: boolean;
  enableViewstateReporting?: boolean;
  disableLayerRemoving?: boolean;
  disableLoading?: boolean;
  disableInternalReferenceResolutionLogic?: boolean;

  reuseMaps?: boolean;
  mapStyle?: Append<CSSProperties>;
  interactive?: Override<boolean>;
};

export function getLocationMapConfigForImageExport(
  config: LocationMapAggregatedOptions,
  resolution: { height?: number | null; width?: number | null }
) {
  const mappingOptions: LocationMapMappingOptions = {
    resolution: {
      override: true,
      value: {
        type: 'manual',
        height: resolution.height ?? 800,
        width: resolution.width ?? 1200
      }
    },
    disableInteractiveControls: true,
    disableInitialAnimation: true
  };
  return mapConfigToLocationMapProps(config, mappingOptions);
}

export function getLocationMapConfigForVideoExport(
  config: LocationMapAggregatedOptions,
  resolution: { height?: number | null; width?: number | null }
) {
  const mappingOptions: LocationMapMappingOptions = {
    resolution: {
      override: true,
      value: {
        type: 'manual',
        height: resolution.height ?? 800,
        width: resolution.width ?? 1200
      }
    },
    disableInteractiveControls: true
  };
  return mapConfigToLocationMapProps(config, mappingOptions);
}

export function mapConfigToLocationMapProps(
  config: LocationMapAggregatedOptions,
  options: LocationMapMappingOptions
): LocationMapCustomConfig {
  const props: LocationMapCustomConfig = { ...config };

  if (options.viewState?.override) {
    props.viewState = {
      ...props.viewState,
      ...options.viewState.value
    };
  }
  props.reuseMaps = options.reuseMaps;
  props.disableInitialAnimation = options.disableInitialAnimation;
  props.disableInternalReferenceResolutionLogic = options.disableInternalReferenceResolutionLogic;
  props.disableLayerRemoving = options.disableLayerRemoving;
  props.disableLoading = options.disableLoading;
  props.enableFeatureEditing = options.enableFeatureEditing;

  if (options.disableInteractiveControls) {
    delete props.fullScreenControls;
    delete props.geolocateControls;
    delete props.mapNavigation;
  }

  if (options.disableText) {
    delete props.title;
    delete props.subtitle;
    delete props.caption;
  }

  if (options.mapStyle?.append) {
    props.mapStyle = { ...props.mapStyle, ...options.mapStyle.value };
  }

  if (options.interactive?.override) {
    props.interactive = options.interactive.value;
  }

  if (options.resolution?.override) {
    props.resolution = options.resolution.value;
  }

  if (options.disableAttribution) {
    props.customAttribution = { enabled: false };
  }
  return props;
}

export type LocationMapPreviewMappingOptions = {
  viewState: { override: true; value: Omit<MapViewState, 'referenceZoom'> } | { override: false };
  border?: string;
};

export function mapConfigToKeyframePreview(
  config: LocationMapAggregatedOptions,
  mappingOptions: LocationMapPreviewMappingOptions
): LocationMapCustomConfig {
  const props: LocationMapCustomConfig = { version: config.version, theme: config.theme };
  props.viewState = config.viewState;
  props.disableInternalReferenceResolutionLogic = true;

  props.layers ??= [];
  props.layers.push({ id: 'labels', visible: false });

  props.mapStyle = { borderRadius: mappingOptions.border, minHeight: 'unset' };
  props.reuseMaps = true;
  props.customAttribution = { enabled: false };
  props.disableLoading = true;

  if (mappingOptions.viewState.override) {
    props.viewState = {
      ...config.viewState,
      ...mappingOptions.viewState.value
    };
  }

  return props;
}

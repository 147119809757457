import classNames from 'classnames';
import React from 'react';
import LockedIcon from 'static/icons/locked.svg';

export enum VizCardSize {
  Small = 'Small',
  Regular = 'Regular',
  Large = 'Large'
}

interface VizCardClasses {
  navLink: string;
  icon: string;
  text: string;
  disabledText: string;
}

type VizPackageCardProps = {
  title: string;
  description: string;
  size: VizCardSize;
  icon: React.ReactNode;
  thumbnail: React.ReactNode;
  disabled?: boolean;
  iconClass?: string;
  disabledText?: string | React.ReactNode;
  displayUnlocked?: boolean;
  onClick: () => void;
};

const getClassesForCardSize = (size: VizCardSize): VizCardClasses => {
  switch (size) {
    case VizCardSize.Large:
      return {
        navLink: 'content-centered flex-col rounded-2xl text-2xl w-[293px] h-[296px] shadow-ev-standard bd-red',
        icon: 'sm:pb-4 sm:h-16 sm:w-14 w-12 pb-2',
        text: 'pt-2 text-base font-bold',
        disabledText: 'absolute left-2 right-2 bottom-8 italic text-lg text-center'
      };
    case VizCardSize.Regular:
      return {
        navLink: 'flex flex-col items-center p-4 rounded text-lg h-24 w-full shadow',
        icon: 'h-9 w-9',
        text: 'pt-2 text-sm font-medium',
        disabledText: 'absolute top-12 italic text-xs text-center'
      };
    default:
      return {} as VizCardClasses;
  }
};

const VizPackageCard = (props: VizPackageCardProps) => {
  const { title, description, icon, thumbnail, size, onClick, disabled, displayUnlocked } = props;

  const parentClasses = classNames(
    'group relative text-ev-navy-blue-2 no-underline',
    getClassesForCardSize(size).navLink,
    {
      'bg-white hover:bg-ev-baby-blue-2 active:bg-ev-navy-blue-2 active:text-white': !displayUnlocked && !disabled
    },
    { 'bg-ev-navy-blue-2': displayUnlocked },
    { 'bg-ev-yellow-6 border-dashed border-2 shadow-none border-grey hover:bg-ev-yellow-6': disabled }
  );

  return (
    <div
      className={`${parentClasses} max-w-sm rounded overflow-hidden shadow-lg bg-white cursor-pointer hover:shadow-xl transition-shadow duration-300`}
      onClick={onClick}
    >
      {disabled && <LockedIcon className="absolute top-1 right-1/2 translate-x-1/2" />}
      <div className="px-2 py-4">
        {thumbnail && <div className="h-36 flex items-center justify-center">{thumbnail}</div>}
        <div className="flex-1 w-full bg-lightgreen flex flex-wrap pt-3 pb-4">
          {icon && <div className="pr-2">{icon}</div>}
          <div className="flex items-center justify-center text-base">{title}</div>
        </div>
        <div className="text-xs">{description}</div>
      </div>
    </div>
  );
};

export default VizPackageCard;

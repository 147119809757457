import { all, call, select, put, takeLatest } from 'redux-saga/effects';
import { cloneDeep, merge } from 'lodash';
import {
  getPackagesProjectWithTeamidAndUuid,
  getPackagesWithTeamid,
  postTeamPackagesThemeUsingTeamidAndId,
  getPackagesSearchWithTeamid,
  postTeamPackageUsingTeamidAndPackageid,
  postTeamPackagesEmbedUsingTeamidAndId,
  postTeamPackageUsingTeamid
} from '../../../api/cloudApiGenerated';
import actionTypes from '../../../redux/actions/action-types';
import { getProfileConfig } from 'redux/selectors/profile';
import { setAction as setPackageConfigAction } from '../actions/packagesPage';
import { setAction as setProjectConfigAction } from '../../../redux/actions/projectConfig';
import { loadModules } from 'editor/core/highcharts-editor';
import { getPackagesPage } from '../selectors/packageEditor';
import { getProjectConfig } from 'redux/selectors/projectConfig';
import { isEBRDPackage, mergeDataToOptions, mergeNameMetadataToPackageProject } from '../utils';
import { selectViewIsLocked, setViewIsLocked } from '../../../redux/reducers/locationMap/viewStateReducer';
import { getMarkerFromPreset } from 'pages/ChartEditorPage/middleware/LocationMap/LocationMapMarker';
import { nanoid } from '@reduxjs/toolkit';
import { updateLocationMapCustomizedAction } from 'pages/ChartEditorPage/actions/locationMap';
import { point } from '@turf/helpers';
import { staticMarkerPresets } from 'pages/ChartEditorPage/middleware/LocationMap/LocationMapMarkerPresetStore';

export type GenericPayload = {
  type: string;
};

type loadPackageProjectPayload = GenericPayload & {
  data: {
    uuid: string;
  };
};

type handleLocationMapPackageSearchPayload = GenericPayload & {
  data: {
    result: {
      label: string;
      value: {
        lat: number;
        lon: number;
      };
    };
  };
};

export function* getTeamPackages(): any {
  const { team } = yield select(getProfileConfig);
  try {
    const data = yield call(getPackagesWithTeamid, team.id);

    yield put(
      setPackageConfigAction({
        packages: data
      })
    );
  } catch (e) {
    console.log(e);
  }
}

export function* searchTeamPackages(params: any): any {
  const { query } = params.data;
  const { team } = yield select(getProfileConfig);
  try {
    const data = yield call(getPackagesSearchWithTeamid, team.id, query);

    yield put(
      setPackageConfigAction({
        packages: data
      })
    );
  } catch (e) {
    console.log(e);
  }
}

export function* savePackageProject(params: any): any {
  const { callback } = params.data;
  const { team } = yield select(getProfileConfig);
  const viewIsLocked = yield select(selectViewIsLocked) ?? false;
  const { packageProject, selectedPackageAndProject } = yield select(getPackagesPage);
  const { projectName, aggregatedOptions, customizedOptions } = yield select(getProjectConfig);

  // TODO: Make it proper for all types of packages
  const metadata = {
    markers: merge(aggregatedOptions.markers ?? [], customizedOptions.markers ?? []),
    viewState: merge(aggregatedOptions.viewState ?? {}, customizedOptions.viewState ?? {}),
    viewIsLocked: viewIsLocked
  };

  const updatedPackageProject = mergeNameMetadataToPackageProject(packageProject, projectName, metadata);

  if (!team.id) return;

  let packageUuid: string | null = null;
  console.log('save viewIsLocked', viewIsLocked);
  try {
    if (packageProject.id) {
      // Update
      yield all([
        call(postTeamPackageUsingTeamidAndPackageid, team.id, updatedPackageProject.id, {
          packageInfo: updatedPackageProject
        }),
        put(
          setProjectConfigAction({
            saved: true,
            changeMade: false,
            lastSaved: new Date()
          })
        )
      ]);
    } else {
      // Create
      const data = yield call(postTeamPackageUsingTeamid, team.id, { packageInfo: selectedPackageAndProject });
      packageUuid = data.uuid as string;

      yield all([
        put(
          setPackageConfigAction({
            packageProject: data
          })
        ),
        put(
          setProjectConfigAction({
            saved: true,
            changeMade: false,
            lastSaved: new Date(),
            aggregatedOptions: {
              ...aggregatedOptions,
              markers: [],
              viewIsLocked
            },
            customizedOptions: {
              ...customizedOptions,
              markers: [],
              viewIsLocked
            }
          })
        )
      ]);
    }
  } catch (e) {
    console.log('Failed saving package!', e);
  } finally {
    if (callback) callback(packageUuid);
  }
}

export function* publishPackageProject(): any {
  const { team } = yield select(getProfileConfig);
  const { packageProject } = yield select(getPackagesPage);

  try {
    if (!team.id || !packageProject.id) return;

    yield put(setProjectConfigAction({ publishLoading: true }));

    const data = yield call(postTeamPackagesEmbedUsingTeamidAndId, team.id, packageProject.id);
    const iframeUrl = data.packageIframe.split('"')[1].replaceAll("'", '');

    yield put(
      setProjectConfigAction({
        tab: isEBRDPackage ? 'customize' : 'publish',
        saved: true,
        changeMade: false,
        embedDetails: {
          iframeCode: data.packageIframe,
          injectCode: data.packageInject,
          iframeURL: iframeUrl
        },
        publishLoading: false,
        published: true,
        lastPublishTime: data.last_publish_time
      })
    );
  } catch (e) {
    yield put(setProjectConfigAction({ publishLoading: false }));
    console.log('Failed publishing package!', e);
  }
}

export function* loadPackageProject(params: loadPackageProjectPayload): any {
  const { uuid } = params.data;

  const { team } = yield select(getProfileConfig);
  const { aggregatedOptions, customizedOptions } = yield select(getProjectConfig);

  try {
    const data = yield call(getPackagesProjectWithTeamidAndUuid, team.id, uuid);
    const themeData = yield call(postTeamPackagesThemeUsingTeamidAndId, team.id, data.team_package_id);
    let theme = null;

    if (themeData.data) theme = JSON.parse(themeData?.data);
    loadModules(theme?.customFonts ?? [], false, null, true);

    yield put(
      setPackageConfigAction({
        packageProject: data,
        themeData: theme
      })
    );

    const iframeUrl = data.embed_code ? data.embed_code.split('"')[1].replaceAll("'", '') : '';
    const projectConfigOptions: any = {};

    if (data.embed_code) {
      projectConfigOptions.embedDetails = {
        iframeCode: data.embed_code,
        injectCode: data.inject_code,
        iframeURL: iframeUrl
      };
      projectConfigOptions.published = true;
    }

    const metadata = JSON.parse(data.metadata);

    if (metadata.viewIsLocked) {
      yield put(setViewIsLocked(true));
    }

    yield put(
      setProjectConfigAction({
        saved: true,
        changeMade: false,
        embedDetails: {
          iframeCode: data.embed_code,
          injectCode: data.inject_code,
          iframeURL: iframeUrl
        },
        published: Boolean(data.embed_code && data.inject_code),
        projectName: data.chart_name,
        cssModules: theme?.customFonts ?? [],
        aggregatedOptions: mergeDataToOptions(aggregatedOptions, data),
        customizedOptions: mergeDataToOptions(customizedOptions, data),
        loadingEditor: true,
        ...projectConfigOptions
      })
    );
  } catch (e) {
    console.log(e);
  }
}

export function* handleLocationMapPackageSearch(params: handleLocationMapPackageSearchPayload): any {
  const { result } = params.data;
  const { customizedOptions } = yield select((state) => state.projectConfig);

  const [newMarker] = cloneDeep(getMarkerFromPreset(nanoid(8), result.label, staticMarkerPresets[1]));
  if (newMarker.icon?.enabled && newMarker?.icon?.src && staticMarkerPresets[0].icon.enabled) {
    newMarker.icon.src = staticMarkerPresets[0].icon.presets[1].svg;
    newMarker.icon.size = staticMarkerPresets[0].icon.presets[1].size;
  }

  if (newMarker.label) {
    newMarker.label.enabled = false;
  }

  const marker = cloneDeep({
    ...newMarker,
    data: { type: 'static', content: point([result.value.lon, result.value.lat]) }
  });

  //@ts-expect-error tooltip not added to location map yet.
  marker.tooltip = {
    text: '',
    align: 'left',
    type: 'panel'
  };

  const { markers } = cloneDeep(customizedOptions ?? { markers: [] });
  markers.push(marker);

  yield put(
    updateLocationMapCustomizedAction({
      optionProps: 'markers',
      val: markers
    })
  );
}

/** Watch functions */
export function* watchGetTeamPackages() {
  yield takeLatest(actionTypes.packagesPage.getTeamPackages, getTeamPackages);
}

export function* watchSearchTeamPackages() {
  yield takeLatest(actionTypes.packagesPage.searchTeamPackages, searchTeamPackages);
}

export function* watchSaveTeamPackages() {
  yield takeLatest(actionTypes.packagesPage.savePackageProject, savePackageProject);
}

export function* watchPublishPackageProject() {
  yield takeLatest(actionTypes.packagesPage.publishPackageProject, publishPackageProject);
}

export function* watchLoadPackageProject() {
  yield takeLatest(actionTypes.packagesPage.loadPackageProject, loadPackageProject);
}

export function* watchHandleLocationMapPackageSearch() {
  yield takeLatest(actionTypes.packagesPage.handleLocationMapPackageSearch, handleLocationMapPackageSearch);
}

export default function* rootSaga() {
  yield all([
    watchGetTeamPackages(),
    watchSearchTeamPackages(),
    watchSaveTeamPackages(),
    watchPublishPackageProject(),
    watchLoadPackageProject(),
    watchHandleLocationMapPackageSearch()
  ]);
}

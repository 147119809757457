export type FixedType = 'string' | 'number' | 'float' | 'date';
type columnsDataType = string | number | null;
const highChartDateFormats = [
  /^\d{4}\/\d{1,2}\/\d{1,2}$/, // YYYY/m/d or YYYY/mm/dd
  /^\d{1,2}\/\d{1,2}\/\d{4}$/, // d/m/YYYY or dd/mm/YYYY or m/d/YYYY or mm/dd/YYYY
  /^\d{1,2}\/\d{1,2}\/\d{2}$/, // d/m/YY or dd/mm/YY or m/d/YY or mm/dd/YY
  /^\d{4}-\d{2}-\d{2}$/, // YYYY-MM-DD
  /^\d{2}-\d{2}-\d{4}$/, // DD-MM-YYYY or MM-DD-YYYY
  /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/, // YYYY-MM-DD HH:MM:SS
  /^\d{4}-\d{2}-\d{2}$/, // YYYY-DD-MM
  /^\d{2}-\d{2}-\d{4}$/, // YY-DD-MM
  /^\d{2}-\d{2}-\d{2}$/, // YY-MM-DD or DD-MM-YY
  /^\d{4}-\d{2}-\d{2}$/, // YYYY-MM-DD
  /^\d{2}-\d{2}-\d{2}$/, // YY-MM-DD or DD-MM-YY
  /^\d{2}\.\d{2}\.\d{4}$/ // DD.MM.YYYY (Norwegian format)
];

const checkType = (value: string | number): FixedType => {
  if (typeof value === 'number') {
    const valueAsString = (value ?? '').toString();
    if (/^0\d+/.test(valueAsString)) {
      // Prioritize string if the number starts with '0' and does not have a decimal point
      if (valueAsString.includes('.') && valueAsString.startsWith('0.')) {
        return 'float'; // Treat as float if it contains a decimal point
      }
      return 'string'; // Treat as string if it starts with '0' and has more digits
    }
    if (Number.isInteger(value)) {
      return 'number';
    }
    return 'float';
  } else {
    // Check if the string matches any date format first
    for (const format of highChartDateFormats) {
      if (format.test(value)) {
        return 'date';
      }
    }
    // Check if the string starts with '0' and has more than one digit
    if (/^0\d+/.test(value)) {
      const valueAsString = (value ?? '').toString();
      // Prioritize string if the number starts with '0' and does not have a decimal point
      if (valueAsString.includes('.') && valueAsString.startsWith('0.')) {
        return 'float'; // Treat as float if it contains a decimal point
      }
      return 'string'; // Treat as string if it starts with '0' and has more digits
    }
    // Check if the string can be converted to an integer
    if (/^-?\d+$/.test(value)) {
      return 'number';
    }
    // Check if the string can be converted to a float
    if (/^-?\d*[.,]\d+$/.test(value)) {
      return 'float';
    }
    return 'string';
  }
};

const determineColumnType = (types: FixedType[]): FixedType => {
  if (types.includes('string')) {
    return 'string';
  }
  if (types.includes('date')) {
    return 'date';
  }
  if (types.includes('float')) {
    return 'float';
  }
  return 'number';
};

export const columnDataType = (data: columnsDataType[][]): FixedType[] => {
  if (data.length < 2) {
    return [];
  }

  const columnCount = data[0].length;

  const columnTypes: FixedType[][] = Array.from({ length: columnCount }, () => []);

  for (let col = 0; col < columnCount; col++) {
    for (let row = 1; row < Math.min(data.length, 100); row++) {
      const value = data[row][col];
      if (value === null || value === undefined) continue;
      const type = checkType(value);
      columnTypes[col].push(type);
    }
  }

  const results: FixedType[] = columnTypes.map((types) => determineColumnType(types));

  return results;
};

export const updateColumnDataType = (
  currentTypes: FixedType[],
  lastChange: [number, number, columnsDataType, columnsDataType],
  dataOptions: columnsDataType[][]
): FixedType[] => {
  const [, col, , newValue] = lastChange;

  if (newValue === null) return currentTypes;

  const newValueType = checkType(newValue);

  let newColumnType = currentTypes[col];
  newColumnType = newValueType;

  if (currentTypes[col] !== newColumnType) {
    const newColumnTypes = columnDataType(dataOptions);
    currentTypes = newColumnTypes;
  }

  return currentTypes;
};

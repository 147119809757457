import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SubscriptionPlans } from '../../../pages/SubscriptionPage/types/plansModels';
import { getPlanById } from '../../../pages/SubscriptionPage/utils/plansHelper';
import { showSignupModalAction } from '../../../redux/actions/projectConfig';
import { getProjectConfig } from '../../../redux/selectors/projectConfig';
import { RootState } from '../../../redux/store';
import { checkCreatePermission, checkPermission, checkPlanPermission } from '../../../utils/profileHelper';
import PrimaryButton from '../../buttons/PrimaryButton';
import { ButtonColor, ButtonType } from '../../buttons/types/ButtonModels';
import { showPaidPlanModal } from '../../utils/paidPlanHelper';
import useToggle from '../../utils/useToggle';
import { hideElementHideButton } from '../../wizard/editor/HideElementButton';
import { onSave } from '../publishHelper';
import { saveCompanyThemeAction, setAction } from 'pages/CompanyThemeEditorPage/actions/ThemeEditorPage';

type ProjectSaveButtonProps = {
  className?: string;
};

export default function ProjectSaveButton({ className }: ProjectSaveButtonProps) {
  const dispatch = useDispatch();
  const projectConfig: RootState['projectConfig'] = useSelector(getProjectConfig);
  const { saved, changeMade, isCompanyThemeEditor } = projectConfig;
  const history = useHistory();

  const { type, inPackagesMode } = useSelector((state: RootState) => state.projectConfig);
  const { isThemeEditor, chosenWizardTemplate } = useSelector((state: RootState) => state.chartEditorPage);
  const profile = useSelector((state: RootState) => state.profile);
  const { user, team, subscription } = profile;
  const [open, toggleOpen] = useToggle(false);
  const hasThemePlanPermission = checkPlanPermission('custom_themes', profile) || false;

  const currentPlan = getPlanById(subscription?.id);
  const isFreePlan = currentPlan === SubscriptionPlans.Free;
  const anonymous = !user && !team;
  const hasBillingSetup = window.hcconfig.billing.enabled;
  const hasChartTypeSavePermission =
    !(isFreePlan && hasBillingSetup && (chosenWizardTemplate as any)?.locked) && checkCreatePermission(type);

  const getSavePermission = () => {
    if (isThemeEditor || isCompanyThemeEditor) {
      return !anonymous && checkPermission('theme_management') && hasThemePlanPermission;
    }
    return !hasChartTypeSavePermission || anonymous || checkPermission('create_chart');
  };
  const hasSavePermission = getSavePermission();

  const showSignupModal = () => dispatch(showSignupModalAction());

  const togglePublishSection = () => {
    toggleOpen();
    hideElementHideButton();
  };

  const saveCompanyThemeAndExit = () => {
    dispatch(
      saveCompanyThemeAction({
        team,
        history,
        cb: () => {
          dispatch(setAction({ view: 'Charts' }));
        }
      })
    );
  };

  const handleSave = () => {
    if (!team) {
      showSignupModal();
      return;
    }
    if (!hasSavePermission) {
      showPaidPlanModal('business');
      return;
    }

    if (!hasChartTypeSavePermission) {
      showPaidPlanModal('starter', undefined, 'chart type');
      return;
    }

    if (!open && !isThemeEditor) togglePublishSection();
    if (saved && !changeMade) return;
    if (isCompanyThemeEditor) {
      saveCompanyThemeAndExit();
    } else {
      onSave(inPackagesMode ? 'package' : type, isThemeEditor, dispatch);
    }
  };

  return (
    <PrimaryButton
      onClick={handleSave}
      text={saved && !changeMade ? 'Saved' : 'Save'}
      buttonColor={ButtonColor.White}
      buttonType={ButtonType.Info}
      disabled={saved && !changeMade}
      className={'mr-2 text-sm ' + className}
      showSubscriptionLock={!hasSavePermission || !hasChartTypeSavePermission}
      tooltip={!hasSavePermission ? 'This feature is not available on your plan' : ''}
    />
  );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocationMapContainerRef } from '@visual-elements/location-map';
import { RootState } from '../../store';
import { revertLocationMapState } from '../../actions/locationMap';
import { startAppListening } from 'redux/listenerMiddleware';
import { setTabAction, setUrlParamAction } from 'redux/actions/typedProjectConfig';
import { inlineUpdateLocationMapMarkerAction } from 'pages/ChartEditorPage/actions/locationMap';

type DefinedLocationMap = Extract<LocationMapContainerRef, { mapDefined: true }>;

export type LocationMapInstanceState = { isPreview: boolean } & (
  | { loadingState: 'none'; locationMap: null; mapDefined: false }
  | {
      locationMap: LocationMapContainerRef;
      mapDefined: false;
      loadingState: 'initial';
    }
  | {
      loadingState: 'initializing';
      mapDefined: true;
      locationMap: DefinedLocationMap;
    }
  | {
      loadingState: 'initialized';
      mapDefined: true;
      locationMap: DefinedLocationMap;
    }
);

const initialState: LocationMapInstanceState = {
  locationMap: null,
  mapDefined: false,
  loadingState: 'none',
  isPreview: false
};

const locationMapInstanceSlice = createSlice({
  name: 'locationMapInstance',
  initialState: initialState as LocationMapInstanceState,
  extraReducers: (builder) => builder.addCase(revertLocationMapState, () => initialState),
  reducers: {
    storeLocationMap(state, action: PayloadAction<LocationMapContainerRef>) {
      if (action.payload.mapDefined) {
        return {
          ...state,
          loadingState: 'initializing',
          locationMap: action.payload,
          mapDefined: true
        };
      }

      return {
        ...state,
        loadingState: 'initial',
        locationMap: action.payload,
        mapDefined: false
      };
    },
    setLocationMapInitialized(state) {
      state.loadingState = 'initialized';
    },
    setIsPreview(state, action: PayloadAction<boolean>) {
      state.isPreview = action.payload;
    }
  }
});

export const selectLocationMap = (state: RootState) => {
  if (
    state.locationMapInstance.loadingState === 'initializing' ||
    state.locationMapInstance.loadingState === 'initialized'
  ) {
    return state.locationMapInstance.locationMap;
  } else return null;
};

// Get the location map instance if finished loading
export const selectInitializedLocationMap = (state: RootState) => {
  if (state.locationMapInstance.loadingState === 'initialized') {
    return state.locationMapInstance.locationMap;
  } else return null;
};

export const selectIsPreviewMap = (state: RootState) => {
  return state.locationMapInstance.isPreview;
};

export const { storeLocationMap, setIsPreview, setLocationMapInitialized } = locationMapInstanceSlice.actions;

export default locationMapInstanceSlice.reducer;

startAppListening({
  actionCreator: setTabAction,
  effect: (action, listenerApi) => {
    if (action.payload.tab === 'type' || action.payload.tab === 'publish') {
      listenerApi.dispatch(setIsPreview(true));
    } else {
      listenerApi.dispatch(setIsPreview(false));
    }
  }
});
startAppListening({
  actionCreator: setUrlParamAction,
  effect: (action, listenerApi) => {
    if (action.payload === 'type' || action.payload === 'publish') {
      listenerApi.dispatch(setIsPreview(true));
    } else {
      listenerApi.dispatch(setIsPreview(false));
    }
  }
});
startAppListening({
  actionCreator: storeLocationMap,
  effect: (action, listenerApi) => {
    const mapRef = action.payload;

    if (mapRef.mapDefined) {
      mapRef.on('initialized', () => {
        listenerApi.dispatch(setLocationMapInitialized());
      });
    }
  }
});

startAppListening({
  actionCreator: setLocationMapInitialized,
  effect: (_, listenerApi) => {
    const { loadingState, locationMap } = listenerApi.getState().locationMapInstance;
    if (loadingState !== 'initialized') return;

    // Put here until we have a marker reducer
    locationMap.on('markerIconChange', (id, data) => {
      listenerApi.dispatch(inlineUpdateLocationMapMarkerAction({ payload: data, id: id, target: 'icon' }));
    });

    locationMap.on('markerLabelChange', (id, data) => {
      listenerApi.dispatch(inlineUpdateLocationMapMarkerAction({ payload: data, id: id, target: 'label' }));
    });
  }
});

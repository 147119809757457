import { LocationMapContainerRef, LocationMapEntryPoint } from '@visual-elements/location-map';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocationMapEditorOptions } from 'redux/selectors/locationMap';
import { selectIsPreviewMap, storeLocationMap } from '../../../redux/reducers/locationMap/instanceReducer';
import { ContextMenu } from '../../editor/generic/locationMap/ContextMenu';
import { ViewBox } from '../../editor/generic/locationMap/ViewBox';

const LocationMapPreview = () => {
  const editorOptions = useSelector(selectLocationMapEditorOptions);
  const isPreview = useSelector(selectIsPreviewMap);
  const dispatch = useDispatch();

  const handleOnEditorMapRefSet = useCallback((ref: LocationMapContainerRef | null) => {
    if (ref) {
      dispatch(storeLocationMap(ref));
    }
  }, []);

  return (
    <div className="relative contents">
      <LocationMapEntryPoint {...editorOptions} ref={handleOnEditorMapRefSet} />
      {!isPreview && <ContextMenu />}
      {!isPreview && <ViewBox />}
    </div>
  );
};

export default LocationMapPreview;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { config } from '@visual-elements/packages';
import paths from 'paths';
import VizPackageCard, { VizCardSize } from './VizPackageCard';
import { savePackageProjectAction, setAction as setPackageConfigAction } from 'pages/PackagesPage/actions/packagesPage';
import { templateIcons } from 'pages/ChartEditorPage/meta/templates/Icons';
import { cloneDeep } from 'lodash';

const VizPackageSection = () => {
  const dispatch = useDispatch();
  const { team } = useSelector((state: RootState) => state.profile);

  const goToPackageEditor = (packageUUID: string, projectUUID: string) => {
    window.location.href = paths.editPackage.replace(':packageuuid', packageUUID).replace(':projectid', projectUUID);
  };

  const handleSave = (packageInfo: any) => {
    const { projects, ...rest } = cloneDeep(packageInfo);
    delete projects[0].component;
    const clonedPackage = {
      ...rest,
      project: {
        ...projects[0],
        chart: { name: 'Untitled project', metadata: JSON.stringify({ markers: [], viewState: {} }), keywords: null }
      }
    };

    dispatch(
      setPackageConfigAction({
        selectedPackageAndProject: clonedPackage,
        packageProject: {},
        themeData: {}
      })
    );
    dispatch(
      savePackageProjectAction({
        callback: function (packageUuid: string) {
          goToPackageEditor(packageInfo.uuid, packageUuid);
        }
      })
    );
  };

  const chartIcons: { [key: string]: JSX.Element } = {
    ebrd: <i className="text-ev-navy-blue-2 fa fa-circle-dollar text-xl" />
  };

  const selectedPackages = (config ?? []).filter((item) => item.uuid === 'ebrd');

  return (
    <div className="bg-ev-yellow-1 w-full">
      {selectedPackages.length && (
        <div className="pb-10">
          <div className="pt-8 ev-sm:pt-14 ev-md:pt-16 pb-4 ev-sm:pb-6 sm:mt-28 mt-10 text-center">
            <h1 className="pl-0 font-bold m-0 ">Or pick a ready template</h1>
          </div>

          <div className="flex justify-center">
            <div className="w-fit grid grid-cols-1 ev-md:grid-cols-2 ev-lg:grid-cols-3 gap-8 mt-14">
              {selectedPackages.map((packageInfo) => {
                const Thumbnail = templateIcons[packageInfo.name.replaceAll(' ', '')] ?? templateIcons['ebrd'];
                const ChartIcon = chartIcons[packageInfo.uuid] ?? (
                  <i className="text-ev-navy-blue-2 fa fa-circle-dollar text-xl" />
                );
                return (
                  <div key={packageInfo.uuid}>
                    <VizPackageCard
                      size={VizCardSize.Large}
                      title={packageInfo.name}
                      description={packageInfo.description}
                      icon={ChartIcon}
                      thumbnail={<Thumbnail />}
                      disabled={!team}
                      disabledText="Login to create a new layout"
                      onClick={() => handleSave(packageInfo)}
                    />
                    {/* TODO: Enable if we have read more links
                    <div className="text-center mt-6">
                      <NavLink to={paths.vizPicker} className="bulk-action-btn ev-red everviz-red">
                        Read More
                      </NavLink>
                    </div> */}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VizPackageSection;

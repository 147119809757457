import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectViewBoxAspectRatio, setViewBoxAspectRatio } from 'redux/reducers/locationMap/viewStateReducer';
import useResizeObserver from 'use-resize-observer';
import SelectWidget from '../../widgets/SelectWidget';
import { LocationMapNavigationControls } from '../../wizard/components/LocationMapNavigationControls';
import { LocationMapSearch } from '../../wizard/editor/LocationMapSearch';

const Divider = () => {
  return <div className="h-5/6 border-l border-ev-grey-3 pt-1 pb-1" />;
};

function getSelectedAspectRatioValue(aspect: number) {
  switch (aspect) {
    case 16 / 9:
      return AspectRatioOptions[1];
    case 9 / 16:
      return AspectRatioOptions[2];
    default:
      return AspectRatioOptions[0];
  }
}

const AspectRatioOptions = [
  {
    label: (
      <>
        <i className="fa fa-regular fa-square text-lg" />
        &emsp;&emsp;1:1
      </>
    ),
    value: 1
  },
  {
    label: (
      <>
        <i className="fa fa-regular fa-rectangle-wide text-lg" />
        &emsp; 16:9
      </>
    ),
    value: 16 / 9
  },
  {
    label: (
      <>
        <i className="fa fa-regular fa-rectangle-vertical text-lg" />
        &emsp;&emsp;9:16
      </>
    ),
    value: 9 / 16
  }
];

export type LocationMapToolbarProps = {
  isCompact: boolean;
  className?: string;
};

export default function LocationMapToolbar({ isCompact, className }: LocationMapToolbarProps) {
  const aspectRatio = useSelector(selectViewBoxAspectRatio);

  const { ref, width } = useResizeObserver();

  const dispatch = useDispatch();

  return (
    <div
      className={'pl-6 pr-6 flex gap-x-3 items-center h-full w-full max-w-screen-ev-lg overflow-x-clip ' + className}
      ref={ref}
    >
      <LocationMapSearch className="flex-1 min-w-[200px] ev-md:min-w-[300px] border-r-[1px]" />
      <div className="flex h-full flex-row gap-6 items-center ">
        {!isCompact && (
          <>
            {(width ?? 0) > 550 && (
              <>
                <Divider />
                <div className="flex flex-row gap-3 items-center justify-center h-full  ">
                  <LocationMapNavigationControls tooltipPlacement="bottom" showDivider={false} />
                </div>
              </>
            )}
            {(width ?? 0) > 750 && (
              <>
                <Divider />
                <SelectWidget
                  optionalLabelClass="text-base text-ev-blue w-full font-semibold"
                  className="max-w-[156px] min-w-[144px] z-10"
                  onChange={(e) => dispatch(setViewBoxAspectRatio(e.val as number))}
                  selectOptions={AspectRatioOptions}
                  value={getSelectedAspectRatioValue(aspectRatio)}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

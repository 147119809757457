import React from 'react';
import PanelContainer from './PanelContainer';
import EBRDMarkersSection from './vizPackage/ebrd/ebrdMarkersSection';
import StockCustomize from './vizPackage/finance/stock';
import { isEBRDPackage } from 'pages/PackagesPage/utils';

type PackageCustomizeProps = {
  active: boolean;
  aggregatedOptions: any;
};

const PackageCustomize: React.FC<PackageCustomizeProps> = (props) => {
  const isFinancePackage = window.location.pathname.includes('/package/edit/finance/');

  return (
    <>
      {props.active && (
        <PanelContainer active={props.active}>
          {isFinancePackage && <StockCustomize />}
          {isEBRDPackage && <EBRDMarkersSection />}
        </PanelContainer>
      )}
    </>
  );
};

export default PackageCustomize;

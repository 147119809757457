import { setResolutionAction } from 'pages/LayoutEditorPage/actions/layoutEditor';
import qs from 'qs';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetThemeAction } from 'redux/actions/projectConfig';
import { setTabAction } from 'redux/actions/typedProjectConfig';
import { RootState } from 'redux/store';
import IconButton from 'shared/buttons/IconButton';
import { ButtonColor } from 'shared/buttons/types/ButtonModels';
import { generateQueryString } from '../../utils/editorHelper';
import { useIsMobile } from '../../utils/mediaHelper';
import LocationMapToolbar from './LocationMapToolbar';
import OptionSelector from './OptionSelector';
import ResolutionSection, { Dimensions } from './ResolutionSection';
import { isEBRDPackage } from 'pages/PackagesPage/utils';

type WorkspaceBarProps = {
  panelOptions: any;
};

export default function WorkspaceBar(props: WorkspaceBarProps) {
  const dispatch = useDispatch();
  const { tab, themeOptions, provider } = useSelector((state: RootState) => state.projectConfig);
  const { aggregatedOptions, showWizard, type, inPackagesMode } = useSelector(
    (state: RootState) => state.projectConfig
  );
  const { isMap } = useSelector((state: RootState) => state.chartEditorPage);
  const isLocationPackage = inPackagesMode && isEBRDPackage;

  useEffect(() => {
    const queryString = generateQueryString(qs, tab, type, showWizard, isMap);
    window.history.replaceState(null, '', window.location.pathname + queryString);
    if ((window as any).Beacon) {
      (window as any).Beacon('suggest');
    }
  }, [tab]);

  const setResolution = (dimensions: Dimensions) => {
    dispatch(setResolutionAction(dimensions));
  };

  const updateSelectedPanel = (e: React.MouseEvent) => {
    const target = e.currentTarget;
    const newTab = target.id;

    if (provider === 'locationMap') {
      setResolution({ width: null, height: null });
    } else if (newTab === 'data' || newTab === 'publish') {
      setResolution({ width: null, height: null });
    }

    dispatch(
      setTabAction({
        tab: newTab
      })
    );
  };

  const onRemoveTheme = () => {
    dispatch(resetThemeAction());
  };

  const goToGSheet = () => {
    const w = window.open(
      `https://docs.google.com/spreadsheets/d/${aggregatedOptions.data.googleSpreadsheetKey}/edit#gid=0`,
      '_blank'
    );
    if (w) w.focus();
  };

  const isMobile = useIsMobile();
  return (
    <div className="bg-white rounded-sm w-full flex h-16 justify-between">
      <div className="flex">
        <OptionSelector updateSelectedPanel={updateSelectedPanel} activePanel={tab} panelOptions={props.panelOptions} />
        {!inPackagesMode && themeOptions.id && (
          <IconButton
            icon="trash"
            onClick={onRemoveTheme}
            buttonColor={ButtonColor.White}
            hoverText="Remove theme"
            className="h-8 px-2 rounded self-center hidden ev-sm:block"
          />
        )}

        {type === 'table' && aggregatedOptions?.data?.googleSpreadsheetKey && (
          <IconButton
            icon="link"
            onClick={goToGSheet}
            buttonColor={ButtonColor.White}
            hoverText="Edit Google sheet"
            className="h-8 px-2 rounded self-center hidden ev-sm:block"
          />
        )}
      </div>
      {provider === 'locationMap' || isLocationPackage ? (
        <>
          {tab === 'publish' && !isMobile ? (
            <div className="flex flex-1 justify-end">
              <ResolutionSection setResolution={setResolution} showPreviewText={true} />
            </div>
          ) : (
            <div className="flex flex-1 justify-center">
              <LocationMapToolbar isCompact={false} className="justify-end" />
            </div>
          )}
        </>
      ) : (
        <div className="flex flex-1 justify-end">
          {tab !== 'data' && <ResolutionSection setResolution={setResolution} showPreviewText={true} />}
        </div>
      )}
    </div>
  );
}

import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { AnimationProgressCallback } from '@visual-elements/location-map';
import { RootState } from '../../store';
import { startAppListening } from '../../listenerMiddleware';
import { revertLocationMapState } from '../../actions/locationMap';
import { setIsPreview, setLocationMapInitialized } from './instanceReducer';
export type AnimationState = {
  isPlaying: boolean;
  playbackIsAtEnd: boolean;
  currentKeyFrameIndex: undefined | number;
  keyFrames: number;
  viewEnabled: boolean;
};

const initialState: AnimationState = {
  playbackIsAtEnd: false,
  isPlaying: false,
  currentKeyFrameIndex: undefined,
  keyFrames: 0,
  viewEnabled: false
};

const animationSlice = createSlice({
  name: 'animation',
  initialState,
  extraReducers: (builder) => builder.addCase(revertLocationMapState, () => initialState),
  reducers: {
    toggleView(state, action: PayloadAction<{ action: 'off' | 'on' | 'toggle' }>) {
      if (action.payload.action === 'on') state.viewEnabled = true;
      else if (action.payload.action === 'off') state.viewEnabled = false;
      else if (action.payload.action === 'toggle') {
        state.viewEnabled = !state.viewEnabled;
      }
    },
    updateAnimationProgress(state, action: PayloadAction<Parameters<AnimationProgressCallback>[0]>) {
      if (action.payload.action === 'tick') {
        if (action.payload.isEnd) {
          state.currentKeyFrameIndex = state.keyFrames - 1;
        } else {
          state.currentKeyFrameIndex = action.payload.keyframeIndex;
        }
      }
      if (action.payload.isEnd) {
        state.playbackIsAtEnd = true;
        state.isPlaying = false;
      } else {
        state.playbackIsAtEnd = false;
      }
    },
    addKeyFrame(
      state,
      action: PayloadAction<{ type: 'index'; index: number } | { type: 'action'; at: 'start' | 'end' }>
    ) {
      const index =
        action.payload.type === 'action' ? (action.payload.at === 'end' ? state.keyFrames : 0) : action.payload.index;

      state.currentKeyFrameIndex = index;
      state.keyFrames++;
    },
    removeKeyFrame(state, action: PayloadAction<number>) {
      if (state.keyFrames === 0) throw new Error('Can not remove keyframes when there are none');
      if (state.currentKeyFrameIndex === undefined) {
        state.currentKeyFrameIndex = action.payload > 0 ? action.payload - 1 : 0;
      } else if (state.currentKeyFrameIndex >= action.payload) {
        state.currentKeyFrameIndex--;
      }
      state.keyFrames--;
    },
    setKeyFrames(state, action: PayloadAction<{ amount: number }>) {
      state.keyFrames = action.payload.amount;
      state.currentKeyFrameIndex = undefined;
    },
    playAnimation(state) {
      state.isPlaying = true;
    },
    pauseAnimation(state) {
      state.isPlaying = false;
    },
    stepCurrentKeyFrame(state, action: PayloadAction<{ direction: 'forward' | 'backward' }>) {
      if (state.currentKeyFrameIndex === undefined) {
        state.currentKeyFrameIndex = 0;
      }
      if (action.payload.direction === 'forward' && state.keyFrames - 1 > state.currentKeyFrameIndex) {
        state.currentKeyFrameIndex++;
      } else if (action.payload.direction === 'backward' && state.currentKeyFrameIndex > 0) {
        state.currentKeyFrameIndex--;
      }
    },
    setCurrentKeyframe(state, action: PayloadAction<{ index: number }>) {
      if (action.payload.index < 0) return;
      state.currentKeyFrameIndex = action.payload.index;
    }
  }
});

export const selectAnimationPlaybackState = (state: RootState) => ({
  isPlaying: state.animation.isPlaying,
  currentKeyFrame: state.animation.currentKeyFrameIndex
});

export const {
  updateAnimationProgress,
  stepCurrentKeyFrame,
  setCurrentKeyframe,
  pauseAnimation,
  playAnimation,
  toggleView,
  addKeyFrame,
  removeKeyFrame,
  setKeyFrames
} = animationSlice.actions;

startAppListening({
  matcher: isAnyOf(setCurrentKeyframe, stepCurrentKeyFrame, removeKeyFrame),
  effect: async (_, listenerApi) => {
    listenerApi.dispatch(pauseAnimation());

    const rootState = listenerApi.getState();
    if (rootState.projectConfig.provider !== 'locationMap') throw new Error('Provider must be location map');

    const animationOptions = rootState.projectConfig.customizedOptions.animation;
    if (!animationOptions) return;

    const currentKeyFrameIndex = rootState.animation.currentKeyFrameIndex;
    if (!rootState.locationMapInstance.mapDefined) {
      throw new Error('Animation controls should be defined');
    }
    if (!animationOptions.enabled) return;

    if ((!animationOptions.keyFrames || animationOptions.keyFrames.length < 1) && animationOptions.initialViewState) {
      rootState.locationMapInstance.locationMap.getMapEngine().jumpTo(animationOptions.initialViewState);
    } else {
      const animationControls = rootState.locationMapInstance.locationMap.getAnimationControls();

      if (!currentKeyFrameIndex || currentKeyFrameIndex === 0) {
        animationControls.seek(0);
      } else if (animationOptions.keyFrames) {
        const keyFrame = animationOptions.keyFrames[currentKeyFrameIndex - 1];
        animationControls.seek(keyFrame.time + keyFrame.duration + (keyFrame.delay ?? 0));
      }
    }
  }
});

startAppListening({
  actionCreator: pauseAnimation,
  effect: async (_, listenerApi) => {
    const rootState = listenerApi.getState();

    if (!rootState.locationMapInstance.mapDefined) {
      return;
    }
    const animationControls = rootState.locationMapInstance.locationMap.getAnimationControls();
    animationControls.pause();
  }
});

startAppListening({
  actionCreator: playAnimation,
  effect: async (_, listenerApi) => {
    const rootState = listenerApi.getState();
    if (rootState.projectConfig.provider !== 'locationMap') throw new Error('Provider must be location map');
    if (rootState.projectConfig.aggregatedOptions.animation.keyFrames.length === 0) {
      listenerApi.dispatch(pauseAnimation());
      return;
    }

    const isPublishTab = rootState.projectConfig.showWizard
      ? rootState.projectConfig.urlParam === 'publish'
      : rootState.projectConfig.tab === 'publish';

    if (!rootState.locationMapInstance.mapDefined) {
      throw new Error('Animation controls should be defined');
    }
    const animationControls = rootState.locationMapInstance.locationMap.getAnimationControls();

    if (rootState.animation.playbackIsAtEnd || isPublishTab) {
      animationControls?.seek(0);
    }
    animationControls?.play();
  }
});

startAppListening({
  actionCreator: setIsPreview,
  effect: (_, listenerApi) => {
    listenerApi.dispatch(pauseAnimation());
  }
});

startAppListening({
  actionCreator: setLocationMapInitialized,
  effect: (_, listenerApi) => {
    const { locationMap, mapDefined } = listenerApi.getState().locationMapInstance;
    if (!mapDefined) throw new Error('Map must be defined');

    locationMap.on('animationProgress', (data) => {
      listenerApi.dispatch(updateAnimationProgress(data));
    });
  }
});

export default animationSlice.reducer;
